/** @prettier */
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Icon from '../icon/Icon';
import ContextMenuItem from './ContextMenuItem';
import './styles.css';
interface ContextMenuProps {
  label?: string;
  icon?: React.ReactElement;
  options: ContextMenuOption[];
  rounded?: boolean;
  type?: 'default' | 'custom';
  blurOnClose?: boolean;
  iconClassName?: string;
  className?: string;
  onVisibilityChange?: (open?: boolean) => void;
  panelClassName?: string;
}

const ContextMenuBody: React.FC<
  ContextMenuProps & {
    open?: boolean;
  }
> = (props) => {
  const callbackRef = useRef<() => void>();

  const {
    options,
    rounded,
    open,
    onVisibilityChange,
    blurOnClose,
    panelClassName,
  } = props;
  const menuButtonRef = useRef<HTMLButtonElement>();
  const btnClasses =
    'p-1 inline-flex justify-center focus:bg-surface-light focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75';
  const handleAfterClose = () => {
    if (!open && menuButtonRef.current && blurOnClose) {
      menuButtonRef.current?.blur();
    }
    /**
     * Callback is called after animation is done
     */
    if (callbackRef.current) {
      callbackRef.current();
      callbackRef.current = undefined;
    }
  };
  const handleItemClick = (callback: () => void) => {
    /**
     * We wont execute props callback just yet but only set local state.
     * We will wait till animation is completed and execute callback there.
     *
     * This is done to prevent stealing focus from elements that have autoFocus property set on them.
     * Headless UI returns focus to the MenuButton after the animation is complete.
     * In our callback function ,if we add/toggle visibility of some elements  with autoFocus property as true
     * they will lose the focus after the animation is complete as headless UI will set the focus to MenuButton
     * That is why we are waiting till the animation is complete and then call the callback function
     * to prevent race conditions with focus.
     */
    /**
     * Interesting Note:  If we use state instead of a ref to hold a reference to our callback function,
     * it ends up executing that function when the setState is called.
     */
    callbackRef.current = callback;
  };

  useEffect(() => {
    onVisibilityChange?.(open);
  }, [open, onVisibilityChange]);
  return (
    <>
      <div>
        {(!props.type || props.type === 'default') && (
          <Menu.Button
            ref={(ref) => (menuButtonRef.current = ref)}
            className={classNames(
              {
                'rounded-full': rounded,
                'bg-surface-light': open,
              },
              btnClasses,
              props.className,
            )}
          >
            {props.label}
            {props.icon && (
              <Icon className={props.iconClassName} icon={props.icon} />
            )}
          </Menu.Button>
        )}
        {props.type === 'custom' && (
          <Menu.Button as={Fragment}>
            <div>{props.children}</div>
          </Menu.Button>
        )}
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        afterLeave={handleAfterClose}
      >
        <Menu.Items
          className={classNames(
            'absolute right-0 w-56 py-3 mt-2 bg-white rounded-lg menu-item origin-top-right focus-visible:outline-none focus:outline-none space-y-3',
            panelClassName,
          )}
        >
          {options.map((option) => (
            <ContextMenuItem
              key={option.id ?? option.label}
              onClick={handleItemClick}
              {...option}
            />
          ))}
        </Menu.Items>
      </Transition>
    </>
  );
};
const ContextMenu: React.FC<ContextMenuProps> = (props) => {
  return (
    <Menu as={Fragment}>
      {({ open }) => {
        return (
          <div
            className={classNames(
              'justify-center relative flex items-center text-left',
              {
                'z-40': open,
              },
            )}
          >
            <ContextMenuBody open={open} {...props} />
          </div>
        );
      }}
    </Menu>
  );
};

export default ContextMenu;
export { ContextMenuProps };
