/** @format */
import React, { ReactElement } from 'react';
import classNames from 'classnames';

interface SignupWithPlanProgressProps {
  stage: number;
  label: string;
  inactive?: boolean;
}

export const SignupWithPlanProgress: React.FC<SignupWithPlanProgressProps> = ({
  stage,
  label,
  inactive = false,
}): ReactElement => {
  return (
    <div
      className={classNames(
        'text-sm flex items-center space-x-2',
        inactive && 'opacity-30',
      )}
    >
      <div className="flex-grow-0 flex-shrink-0 w-8 h-8 pt-1.5 text-xs text-sm text-center text-white bg-black rounded-full">
        {stage}
      </div>
      <div className="-mt-0.5">{label}</div>
    </div>
  );
};
