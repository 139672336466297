/** @prettier */
/* eslint-disable i18next/no-literal-string */
import React, { useState, useEffect } from 'react';
import Logo from '../../logo/Logo';
import { ProjectDropdownContainer } from '../../menus/ProjectDropdown';
import Button from '../../button/Button';
import { UserDropdownContainer } from '../../menus/UserDropdown';
import { VersionDropdown } from '../../menus/VersionDropdown';
import { EditableTitle } from '../../common/EditableTitle';
import MoreOptions from '../../menu/MoreOptions';
import type { IStoryboard } from 'javascripts/types/storyboard';
import type { PanelbarStore } from 'javascripts/flux/stores/panelbar';
import { useTranslation } from 'react-i18next';
import {
  useCanShowWordCount,
  WordCountContainer,
} from '../presentation/WordCount';
import { WithSeparator } from 'blackbird/components/common/WithSeparator';
import { useStore } from 'javascripts/helpers/useStore';
import { StoryboardDownloadButton } from 'javascripts/components/storyboard/ExportPopup/StoryboardDownloadButton';
import {
  createPermissionFlyoverOpener,
  GetPermission,
} from 'javascripts/helpers/has-permission';
import { StoryboardActions } from 'javascripts/flux/actions/storyboard';
import * as can from 'javascripts/helpers/restricted-actions.js';
import { TourHintable } from 'javascripts/components/tours/TourHintable';
import Tooltip from 'blackbird/components/feedback/tooltip/Tooltip';
import {
  StoryboardDurationContainer,
  useCanShowDuration,
} from '../presentation/StoryboardDuration';
import Avatar from 'blackbird/components/avatar/Avatar';
import { WizardDropdownContainer } from 'blackbird/components/wizard/WizardDropdownContainer';
import { StoryboardBreadcrumbContainer } from 'blackbird/components/storyboard_breadcrumb/StoryboardBreadcrumbContainer';
import classNames from 'classnames';
import { TeamStoryboardShareContainer } from 'blackbird/components/team/TeamStoryboardShareContainer';

export const StoryboardHeaderBar: React.VFC<{
  storyboard: IStoryboard;
  onStoryboardTitleChange: (newName: string) => void;
  onArchiveStoryboard: () => void;
  onDuplicateStoryboard: () => void;
  onWatchTutorial: () => void;
  onFormatting: () => void;
  onDownload: () => void;
  onShare: () => void;
  onPresent: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const action = (str) => t('actions.storyboard.' + str);

  const [hideStoryboardMetaData, setHideStoryboardMetaData] =
    useState<boolean>(true);

  const isPanelbarOpen = useStore<boolean, PanelbarStore>(
    'panelbar',
    (store) => store.isOpen,
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1300 && isPanelbarOpen) {
        setHideStoryboardMetaData(true);
      } else if (window.innerWidth < 900) {
        setHideStoryboardMetaData(true);
      } else {
        setHideStoryboardMetaData(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isPanelbarOpen]);

  const hasFrames = useStore('FrameStore', (state) => state.frames.length > 0);
  const options: ContextMenuOption[] = [
    {
      id: 'archive',
      label: action('archive'),
      onItemSelect: props.onArchiveStoryboard,
    },
    {
      id: 'duplicate',
      label: action('duplicate'),
      onItemSelect: props.onDuplicateStoryboard,
    },
    {
      id: 'tutorial',
      label: action('tutorial'),
      onItemSelect: props.onWatchTutorial,
    },
    {
      id: 'formatting',
      label: action('formatting'),
      onItemSelect: props.onFormatting,
    },
  ];
  const { storyboard } = props;
  const versions = storyboard.versions;
  const handleShare = (hasPermission) => () => {
    if (!hasPermission) {
      return createPermissionFlyoverOpener(
        storyboard.project.owner.user_id,
        'shareable',
      );
    }
    props.onShare();
  };

  const hideWordCount = useStore<boolean, PanelbarStore>(
    'panelbar',
    (s) => s.isOpen && s.panelType === 'scriptEditor',
  );

  const canShowDuration = useCanShowDuration();
  const canShowWordCount = useCanShowWordCount();

  const handleNewVersion = (
    copyComments: boolean,
    copyFrameStatuses: boolean,
  ) => {
    if (can().access({ type: 'newVersion' }) === true) {
      StoryboardActions.version({
        storyboard,
        copyComments,
        copyFrameStatuses,
      });
    }
  };

  const MetaInfo: React.FC = () => {
    return (
      <div className="flex items-center text-sm gap-2">
        <WithSeparator
          separator={
            <div className="flex-shrink-0 w-px h-3.5 mt-0.5 bg-border" />
          }
        >
          {!hideStoryboardMetaData && !hideWordCount && canShowWordCount && (
            <WordCountContainer storyboardId={props.storyboard.id} />
          )}
          {!hideStoryboardMetaData && canShowDuration && (
            <StoryboardDurationContainer storyboardId={props.storyboard.id} />
          )}
        </WithSeparator>
      </div>
    );
  };

  return (
    <header
      className={classNames(
        'flex items-center pl-5 pr-0 bg-white border-b border-border',
        BoordsConfig.HasV3 ? 'pt-3 pb-3.5' : 'py-3 fullsize:py-5 ',
      )}
    >
      {!BoordsConfig.HasV3 && (
        <a href="/" className="mt-1 mr-6">
          <Tooltip title={t('dashboard.goToDashboard')} distance={10}>
            {storyboard.project.owner.logo_url ? (
              <Avatar
                className="flex-shrink-0"
                size="lg"
                image={storyboard.project.owner.logo_url}
              />
            ) : (
              <Logo mark className="flex-shrink-0 w-11" />
            )}
          </Tooltip>
        </a>
      )}

      {/*
        Storyboard name + project container
        This w-0 will cause this container to fit neatly in the available space when there is't that much space left. Without it, the truncate class won't do anything. */}
      <div className="flex-auto w-0 mr-6">
        {BoordsConfig.HasV3 && (
          <div className="mt-1 mb-0.5">
            <StoryboardBreadcrumbContainer storyboardId={storyboard.id} />
          </div>
        )}
        {/* Name + version */}
        <div className="flex items-center w-full mb-1 gap-2">
          <EditableTitle
            autoFocus={false}
            isEditable
            value={storyboard.document_name}
            onChange={props.onStoryboardTitleChange}
          />
          {versions.length > 0 && (
            <VersionDropdown
              versions={versions}
              storyboardOwnerId={storyboard.project.owner.user_id}
              selectedVersion={storyboard.version_number}
              onNewVersion={handleNewVersion}
            />
          )}
          {BoordsConfig.HasV3 && <MetaInfo />}
        </div>

        {!BoordsConfig.HasV3 && (
          <div className="flex items-center gap-2">
            <ProjectDropdownContainer />
            <MetaInfo />
          </div>
        )}
      </div>

      {/* Right side of this toolbar */}
      <div className="flex flex-shrink-0 ml-auto gap-3">
        {BoordsConfig.PermittedActions.includes('storyboards.delete') && (
          <MoreOptions panelClassName="top-3/4" options={options} />
        )}

        <WizardDropdownContainer />

        <StoryboardDownloadButton
          title={action('download')}
          disabled={!hasFrames}
        />

        {BoordsConfig.HasV3 ? (
          <TeamStoryboardShareContainer
            teamId={String(storyboard.project.owner.user_id)}
            storyboard={storyboard}
          />
        ) : (
          <>
            <GetPermission
              storyboardOwnerId={storyboard.project.owner.user_id}
              featureName="shareable"
            >
              {(hasPermission) => (
                <Tooltip
                  title={`Please upgrade to the Workflow plan to share presentation links`}
                >
                  <Button
                    link={props.storyboard.public_url}
                    type="outline"
                    disabled={!hasFrames || !hasPermission}
                  >
                    {action('present')}
                  </Button>
                </Tooltip>
              )}
            </GetPermission>

            <GetPermission
              storyboardOwnerId={storyboard.project.owner.user_id}
              featureName="shareable"
            >
              {(hasPermission) => (
                <TourHintable step="openShare" overlayPosition="bottom">
                  <Button
                    onClick={handleShare(hasPermission)}
                    type="solid"
                    disabled={!hasFrames}
                  >
                    {action('share_short')}
                  </Button>
                </TourHintable>
              )}
            </GetPermission>
          </>
        )}

        <div className="flex items-center mr-3">
          <UserDropdownContainer />
        </div>
      </div>
    </header>
  );
};
