/** @prettier */
import * as React from 'react';
import { useStore } from '../../helpers/useStore';
import Select from 'blackbird/components/form/select/Select';
import { StoryboardActions } from '../../flux/actions/storyboard';
import { type TFunction, useTranslation } from 'react-i18next';
import type { Option } from 'blackbird/components/common/types';
import { memoize } from 'underscore';
import { notFalse } from 'javascripts/helpers/notUndefined';

const getSizes = memoize((t: TFunction): Option[] => {
  return [
    {
      label: t('aspectRatios.portrait'),
      value: '9x16',
    },
    {
      label: t('aspectRatios.landscape'),
      value: '16x9',
    },
    {
      label: t('aspectRatios.square'),
      value: '1x1',
    },
    {
      label: t('aspectRatios.social'),
      value: '4:5',
    },
    {
      label: t('aspectRatios.tv'),
      value: '4:3',
    },
    {
      label: t('aspectRatios.widescreen'),
      value: '1.85:1',
    },
    {
      label: t('aspectRatios.anamorphic'),
      value: '2.4:1',
    },
  ].filter(notFalse);
});

const onChange = (value: string) => {
  StoryboardActions.switchAspectRatio(value);
};

const NewStoryboardAspectRatio: React.FC = () => {
  const { t } = useTranslation();
  const value = useStore<string>('storyboard', (s) => s.new_storyboard_ratio);

  return (
    <Select
      disableInput
      label={t('modals.new_storyboard.labels.frame_size')}
      size="md"
      selectContainerClassName="w-full"
      options={getSizes(t)}
      value={value}
      onChange={onChange}
    />
  );
};

export default NewStoryboardAspectRatio;
