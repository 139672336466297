/** @prettier */
import React from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Banner from 'blackbird/components/feedback/banner/Banner';
import { OnboardingProps, OnboardingType } from './Onboarding';
import { useTranslation } from 'react-i18next';

const ResetPasswordForm: React.FC<OnboardingProps> = (props) => {
  const { notification } = props;
  const handleGoBack = () => {
    props.onToggleScreen && props.onToggleScreen(OnboardingType.SIGN_IN);
  };
  const handleSubmit = (e) => {
    if (props.onResetPassword) {
      e.preventDefault();
      const form = e.target;
      const email = form['user[email]'].value;
      props.onResetPassword(email);
      return;
    } else if (!props.authenticityToken || !props.formAction) {
      e.preventDefault();
      throw new Error(
        'An authenticity token and formAction prop need to be supplied',
      );
    }
  };

  const { t } = useTranslation();

  return (
    <form
      onSubmit={handleSubmit}
      className="flex items-center justify-center flex-grow"
      method="post"
      action={props.formAction}
    >
      <input name="utf8" type="hidden" value="✓" />
      {props.authenticityToken && (
        <input
          type="hidden"
          name="authenticity_token"
          value={props.authenticityToken}
        />
      )}

      <fieldset className="flex flex-col flex-grow max-w-md space-y-8">
        <legend className="text-2xl text-type-primary">
          {t('accounts.resetPassword.title')}
        </legend>
        {!!notification && (
          <Banner
            message={notification.message}
            kind={notification.kind}
            className="w-full-imp"
          />
        )}
        <div className="flex flex-col gap-6">
          <TextInput
            name="user[email]"
            label="Email"
            className="flex-grow-0"
            type="email"
          />
          <Button htmlType="submit" size="lg" rounded>
            {t('accounts.resetPassword.button')}
          </Button>
          <Button
            onClick={handleGoBack}
            type="text"
            className="self-center font-normal underline"
          >
            {t('accounts.resetPassword.back')}
          </Button>
        </div>
      </fieldset>
    </form>
  );
};
export default ResetPasswordForm;
