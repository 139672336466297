/** @prettier */
import Button from 'blackbird/components/button/Button';
import * as React from 'react';

const reloadWindow = () => window.location.reload();

export interface IProps {
  header?: string;
  text?: string;
  size?: 'large' | 'small';
  /**
   * Add a background class to the container, like `bg-white-semi-translucent` */
  bg?: string;
}

export class ErrorBoundaryContent extends React.Component<IProps> {
  static defaultProps = {
    header: 'Please refresh your browser',
    size: 'large',
    text: "We've detected an unexpected error on this page. To make sure you don't lose any future work, please you refresh your web browser.\n\nOur development team have been notified, and are looking into the cause of the problem. If the issue persists, please contact us via the chat icon.",
  };

  render() {
    const wrapperClassName =
      this.props.size === 'large'
        ? 'my-12 mw7 tl block-center p-10 bg-white shadow-md text-lg  rounded-md'
        : 'p-6 bg-surface-critical m-6 rounded-md';

    const headerClassName =
      this.props.size === 'large'
        ? 'text-2xl mb-6'
        : 'font-base font-semibold mb-4';

    const copyClassName =
      this.props.size === 'large' ? 'max-w-lg my-8' : 'mt-4 mb-6 font-base';

    const buttonSize = this.props.size === 'large' ? 'md' : 'sm';

    return (
      <div className={wrapperClassName}>
        <div className={`${headerClassName}`}>{this.props.header}</div>

        <Markdown text={this.props.text} className={copyClassName} />

        <div className="mt-4">
          <Button
            size={buttonSize}
            type="solid"
            onClick={reloadWindow}
            autoFocus
          >
            Reload page
          </Button>
        </div>
      </div>
    );
  }
}
