/** @prettier */

import React, {
  useState,
  forwardRef,
  useMemo,
  type FocusEventHandler,
} from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid/non-secure';
import { isNumber } from 'underscore';
import RichTextInput, {
  type RichTextInputChangeEvent,
  type RichTextInputChangeEventHandler,
  type RichTextInputProps,
} from '../richTextInput/RichTextInput';

interface FrameProps extends CommonProps {
  hasFocus: boolean;
  htmlFor?: string;
  wrap?: boolean;
}

interface CommonProps {
  error?: string;
  label?: React.ReactNode;
  className?: string;
  /** This is actually required, but needs to be marked as optional here to
   * not cause type conflicts  */
  id?: string;
  noBorder?: boolean;
  disabled?: boolean;
  bottomComponent?: React.ReactNode;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  /**
   * Cannot name it just size as that is a conflicting property with InputHTMLAttributes
   */
  inputSize?: 'md' | 'lg' | 'xs';
  labelClasses?: string;
}

export interface TextInputProps
  extends CommonProps,
    React.InputHTMLAttributes<any> {
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  inputClassName?: string;
}
interface TextAreaProps extends CommonProps, RichTextInputProps {
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onChange?: RichTextInputChangeEventHandler;
  textAreaClassName?: string;
  /** Allow markdown formatting */
  plainText?: boolean;
}

const InputFrame: React.FC<FrameProps> = ({
  label,
  className,
  id,
  htmlFor,
  hasFocus,
  inputSize = 'lg',
  labelClasses,
  ...props
}) => {
  return (
    <div className={classNames('group min-w-m flex-auto', className)}>
      {label && (
        /** Margin bottom doesn't work if label isn't set to block */
        <label
          id={id}
          htmlFor={htmlFor}
          className={classNames(
            'mb-1.5 block cursor-pointer min-w-max w-4/12',
            labelClasses,
          )}
        >
          {label}
        </label>
      )}

      <div
        className={classNames(
          'flex items-center w-full rounded-sm relative ring-inset',
          !props.noBorder && (hasFocus ? 'ring-2' : 'ring-1'),
          props.wrap && 'flex-wrap',
          {
            'ring-form-error': props.error && !props.noBorder,
            'ring-border-dark': hasFocus && !props.error && !props.noBorder,
            'ring-form': !hasFocus && !props.error && !props.noBorder,
            'bg-form-disabled-bg': props.disabled,
          },
        )}
      >
        {props.children}
      </div>

      {props.error && (
        <div role="alert" className="text-form-error text-sm mt-1.5">
          {props.error}
        </div>
      )}
    </div>
  );
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      bottomComponent,
      className,
      disabled,
      error,
      label,
      leftComponent,
      noBorder = false,
      rightComponent,
      inputSize = 'lg',
      inputClassName,
      labelClasses,
      ...restProps
    } = props;

    const [id] = useState(props.id ?? nanoid(10));
    const [focused, setFocused] = useState(false);

    const handleFocus = (e) => {
      setFocused(true);
      props.onFocus?.(e);
    };

    const handleBlur = (e) => {
      setFocused(false);
      props.onBlur?.(e);
    };

    return (
      <InputFrame
        htmlFor={id}
        label={label}
        hasFocus={focused}
        className={className}
        noBorder={noBorder}
        error={error}
        labelClasses={labelClasses}
        inputSize={inputSize}
      >
        {leftComponent && (
          <div
            className={classNames('absolute', {
              'left-4': inputSize === 'lg',
              'left-2': inputSize === 'md',
              'left-0': inputSize === 'xs',
            })}
          >
            {leftComponent}
          </div>
        )}

        <input
          ref={ref}
          {...restProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
          id={id}
          className={classNames(
            'flex-1 w-full placeholder-text-type-empty outline-none bg-transparent',
            'disabled:text-form-disabled',
            !inputClassName?.includes('p-') && {
              'p-4': inputSize === 'lg',
              'p-2': inputSize === 'md',
              'p-0': inputSize === 'xs',
            },
            {
              'pl-11': inputSize === 'lg' && leftComponent,
              'pl-9': inputSize === 'md' && leftComponent,
              'pl-7': inputSize === 'xs' && leftComponent,
              'pr-11': inputSize === 'lg' && rightComponent,
              'pr-9': inputSize === 'md' && rightComponent,
              'pr-7': inputSize === 'xs' && rightComponent,
            },
            inputClassName,
          )}
          aria-invalid={error ? 'true' : 'false'}
          onChange={props.onChange}
          disabled={disabled}
        />
        {rightComponent && (
          <div
            className={classNames('absolute', {
              'right-4': inputSize === 'lg',
              'right-2': inputSize === 'md',
              'right-0': inputSize === 'xs',
            })}
          >
            {rightComponent}
          </div>
        )}
        {bottomComponent}
      </InputFrame>
    );
  },
);

TextInput.displayName = 'TextInput';

export const TextArea = forwardRef<HTMLDivElement, TextAreaProps>(
  (props, ref) => {
    const {
      bottomComponent,
      className,
      disabled,
      error,
      label,
      leftComponent,
      noBorder = false,
      rightComponent,
      inputSize = 'lg',
      textAreaClassName,
      labelClasses,
      spellCheck = true,
      preParsed,
      ...restProps
    } = props;

    const [id] = useState(props.id ?? nanoid(10));
    const [focused, setFocused] = useState(false);

    const handleFocus = (e) => {
      setFocused(true);
      props.onFocus?.(e);
    };

    const handleBlur = (e) => {
      setFocused(false);
      props.onBlur?.(e);
    };

    const placeholderClasses = useMemo(
      () => textAreaClassName?.match(/!?placeholder-[\w.-]+/)?.length,
      [textAreaClassName],
    );

    const hasBottomComponent =
      Boolean(bottomComponent) || isNumber(props.maxCharacters);

    return (
      <InputFrame
        id={id}
        label={label}
        hasFocus={focused}
        className={className}
        noBorder={noBorder}
        error={error}
        aria-invalid={error ? 'true' : 'false'}
        labelClasses={labelClasses}
        inputSize={inputSize}
        wrap={hasBottomComponent}
      >
        {leftComponent && (
          <div
            className={classNames('absolute', {
              'left-4': inputSize === 'lg',
              'left-2': inputSize === 'md',
              'left-0': inputSize === 'xs',
            })}
          >
            {leftComponent}
          </div>
        )}

        <RichTextInput
          ref={ref}
          {...restProps}
          onChange={props.onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-labelledby={id}
          className={classNames(
            'flex-1 w-full  outline-none bg-transparent',
            !textAreaClassName?.includes('p-') && {
              'p-4': inputSize === 'lg',
              'p-2': inputSize === 'md',
              'p-0': inputSize === 'xs',
            },
            {
              'pl-11': inputSize === 'lg' && leftComponent,
              'pl-9': inputSize === 'md' && leftComponent,
              'pl-7': inputSize === 'xs' && leftComponent,
              'pr-11': inputSize === 'lg' && rightComponent,
              'pr-9': inputSize === 'md' && rightComponent,
              'pr-7': inputSize === 'xs' && rightComponent,
            },
            {
              'text-form-disabled': disabled,
              'placeholder-text-type-empty': !placeholderClasses,
            },
            textAreaClassName,
          )}
          spellCheck={spellCheck}
          disabled={disabled}
          plainText={props.plainText}
          preParsed={preParsed}
        />
        {rightComponent && (
          <div
            className={classNames('absolute', {
              'right-4': inputSize === 'lg',
              'right-2': inputSize === 'md',
              'right-0': inputSize === 'xs',
            })}
          >
            {rightComponent}
          </div>
        )}

        {hasBottomComponent ? (
          <div className="basis-full flex gap-2">
            <div className="flex-auto">{bottomComponent}</div>
            {/* make some room for the character count */}
            {props.maxCharacters && <div className="h-6 w-4 shrink-0 " />}
          </div>
        ) : null}
      </InputFrame>
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextInput;

export type TransformingTextAreaChangeHandler<I> = (
  newValue: I,
  e: RichTextInputChangeEvent,
  name?: string,
) => void;
interface TransformingTextAreaProps<I = Record<string, any>>
  extends Omit<TextAreaProps, 'value' | 'onChange'> {
  value?: I;
  transformIn: (input?: I) => string;
  transformOut: (string) => I;
  onChange: TransformingTextAreaChangeHandler<I>;
}

export const TransformingTextArea = forwardRef<
  HTMLDivElement,
  TransformingTextAreaProps
>((props, ref) => {
  const {
    value: inputValue,
    transformIn,
    transformOut,
    onChange,
    ...rest
  } = props;
  const [value, setValue] = React.useState<string>(transformIn(inputValue));

  React.useEffect(() => {
    setValue(transformIn(inputValue));
  }, [inputValue, transformIn]);

  const handleChange = React.useCallback<RichTextInputChangeEventHandler>(
    (newValue, e) => {
      onChange?.(transformOut(newValue), e, props.name);
    },
    [onChange, transformOut, props.name],
  );

  return (
    <TextArea
      {...rest}
      value={value}
      onChange={handleChange}
      ref={ref}
      preParsed
    />
  );
});

TransformingTextArea.displayName = 'TransformingTextArea';
