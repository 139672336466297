/** @prettier */
import { eachFrameFieldMap } from '../../../helpers/eachFrameFieldMap';
import { detectCharset } from '../../../helpers/detectCharset';
import type { IStoryboardInStore, FrameField } from '../../../types/storyboard';
import type { IFrame } from '../../../types/frame';
import { userhas24hClock } from '../../../helpers/userHas24hClock';
import { getWordCount } from '../../../helpers/getWordCount';
import { removeUnsupportedCharacters } from '../../../helpers/removeUnsupportedCharacters';
import {
  notNull,
  notNullOrUndefined,
  notUndefined,
} from '../../../helpers/notUndefined';
import format from 'date-fns/format';
import { getFrameField } from 'javascripts/helpers/fieldDataHelpers';
import { indexBy, isUndefined, times } from 'underscore';
import { canShowWordCount } from 'blackbird/components/headers/presentation/WordCount';
import { flattenHTMLTokens } from 'javascripts/components/pdf/helpers/textHelpers/flattenHTMLTokens';
import type {
  TableCell,
  Paragraph,
  ParagraphChild,
  Table,
  IParagraphOptions,
} from 'docx';
import { parseAndSanitizeText } from 'blackbird/helpers/parseAndSanitizeText';

// I got this from https://github.com/dolanmiu/docx/issues/349
const maxWidth = 9638;
const numberColumnWidth = 800;
const tableMargins = { top: 75, bottom: 75, right: 100, left: 100 };
const lineSpacing = 1.3 * 240;
const paragraphStyle = {
  spacing: { line: lineSpacing },
};
const font = { name: 'Arial' };
const UNORDERED_LIST = 'UNORDERED_LIST';
const ORDERED_LIST = 'ORDERED_LIST';

/** We want this to be false to give frames with a loooot of text the
 * ability to be split across pages. When this is true, and the contents don't
 * fit on one page, they would actually cut off the content.
 * Of course for frames that have less contents it is neater to push them to
 * the next page, but there is no middle ground here and having content be
 * hidden is worse than the "aesthetic" discomfort */
const cantSplit = false;

type callbackField = Parameters<typeof eachFrameFieldMap>[1];

function forEachField<T>(
  fields: FrameField[],
  skipReference = true,
  callback: callbackField,
): T[] {
  return eachFrameFieldMap<T>(fields, (field, fieldName, index, label) => {
    if (fieldName === 'reference' && skipReference) return;
    return callback(field, fieldName, index, label) as any;
  }).filter(notNullOrUndefined);
}

export const generateDocx = (
  storyboard: IStoryboardInStore,
  frames: IFrame[],
) =>
  new Promise((resolve, reject) =>
    import('docx')
      .then((docx) => {
        // This is a super weird way of requiring this library, but there's something
        // going wrong with importing (perhaps it's the lack of the esModuleInterop
        // setting in the typescript config, but our whole app is built on that, and
        // would take a lot of time to fix. for now, this works)
        const {
          Document,
          Packer,
          Paragraph,
          TextRun,
          HeadingLevel,
          Table,
          TableRow,
          TableCell,
          WidthType,
          TableLayoutType,
          ExternalHyperlink,
        } = docx;

        // Create document
        const timeFormat = userhas24hClock() ? 'HH:mm' : 'h:mmaaa';

        // e.g 19 May 2020, 9:36am
        const now = format(new Date(), `dd MMM yyyy, ${timeFormat}`);
        const version = 'v' + storyboard.version_number;
        const wordCountFrom = storyboard.preferences?.word_count_from;
        const countAllFields = isUndefined(wordCountFrom)
          ? storyboard.preferences?.word_count_all_fields
          : wordCountFrom === 'all';

        const { wordCount, fieldsUsed } = getWordCount(
          frames,
          storyboard.frame_fields,
          storyboard.preferences!,
        );

        const fieldNameLookup = indexBy(storyboard.frame_fields, 'id');
        const wordCountType = countAllFields
          ? fieldsUsed
              .map((fieldId) => fieldNameLookup[fieldId].label)
              .join(', ')
          : 'voiceover';

        const pageElements: (Paragraph | Table)[] = [];

        // First, add the headers
        pageElements.push(
          new Paragraph({
            ...paragraphStyle,
            children: [
              new TextRun({
                font: font,
                text: `${storyboard.document_name} ${version}`,
                color: '000000',
                bold: true,
                size: 13 * 2,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
            spacing: { after: 200 },
          }),
          new Paragraph({
            ...paragraphStyle,
            children: [
              new TextRun({
                font: font,
                text: `Generated ${now}`,
                size: 10 * 2,
                color: '999999',
              }),
              canShowWordCount(storyboard.preferences)
                ? new TextRun({
                    font: font,
                    text: `${wordCount} words (${wordCountType})`,
                    size: 10 * 2,
                    color: '000000',
                    break: 1,
                  })
                : undefined,
            ].filter(notUndefined),
          }),
          new Paragraph({
            ...paragraphStyle,
            children: [new TextRun({ size: 10 * 2 })],
          }),
        );

        const headerCells: TableCell[] = [
          new TableCell({
            children: [],
          }),
          ...forEachField<TableCell>(
            storyboard.frame_fields,
            !countAllFields,
            (field, id, i, label) => {
              return new TableCell({
                margins: tableMargins,
                children: [
                  new Paragraph({
                    ...paragraphStyle,
                    children: [
                      new TextRun({
                        font: font,
                        text: label,
                        size: 10 * 2,
                        bold: true,
                        color: '000000',
                      }),
                    ],
                  }),
                ],
              });
            },
          ),
        ].filter(notNull);

        const tableHeader = new TableRow({
          tableHeader: true,
          children: headerCells,
        });

        // Better to be sure, there was a bug that made some frame_fields an object
        let fieldCount = 0;
        forEachField(storyboard.frame_fields, !countAllFields, (f) => {
          if (f.isEnabled) fieldCount++;
          return fieldCount;
        });

        const tableContents = frames.map((frame) => {
          return new TableRow({
            cantSplit,
            children: [
              new TableCell({
                margins: tableMargins,
                children: [
                  new Paragraph({
                    ...paragraphStyle,
                    text: frame.number || frame.sort_order,
                  }),
                ],
              }),

              ...forEachField<TableCell>(
                storyboard.frame_fields,
                !countAllFields,
                (field, fieldId) => {
                  const content = removeUnsupportedCharacters(
                    getFrameField(frame, fieldId),
                  );
                  const { isRtl } = detectCharset(content);
                  const sanitized = parseAndSanitizeText(content, true);
                  const tokens = flattenHTMLTokens(sanitized);

                  // Top-level children (for the table cell)
                  const tableCellChildren: Paragraph[] = [];
                  let currentParagraphChildren: ParagraphChild[] = [];
                  let numbering: IParagraphOptions['numbering'] | undefined;

                  const finishParagraph = () => {
                    tableCellChildren.push(
                      new Paragraph({
                        ...paragraphStyle,
                        // This needs to be true for RTL (BIDI just means rtl here :/)
                        bidirectional: isRtl,
                        children: currentParagraphChildren,
                        numbering: numbering,
                      }),
                    );
                    currentParagraphChildren = [];
                  };

                  tokens.forEach((token) => {
                    if (token.type === 'space') {
                      finishParagraph();
                      numbering = undefined;
                    } else if (token.type === 'text') {
                      if (token.list?.type === 'ordered') {
                        numbering = {
                          reference: UNORDERED_LIST,
                          level: token.list.level,
                        };
                      } else if (token.list?.type === 'unordered') {
                        numbering = {
                          reference: ORDERED_LIST,
                          level: token.list.level,
                        };
                      }
                      const textProps = {
                        font: font,
                        text: token.text,
                        strike: token.isDel,
                        rightToLeft: token.isRtl,
                        bold:
                          token.fontStyle === 'bold' ||
                          token.fontStyle === 'bolditalic',
                        italics:
                          token.fontStyle === 'italic' ||
                          token.fontStyle === 'bolditalic',
                      };
                      if (token.link) {
                        currentParagraphChildren.push(
                          new ExternalHyperlink({
                            children: [
                              new TextRun({ ...textProps, style: 'Hyperlink' }),
                            ],
                            link: String(token.link),
                          }),
                        );
                      } else {
                        currentParagraphChildren.push(new TextRun(textProps));
                      }
                    }
                  }); // end of forEach tokens

                  finishParagraph();

                  return new TableCell({
                    margins: tableMargins,
                    children: tableCellChildren,
                  });
                },
              ),
            ].filter(Boolean),
          });
        });

        const columnWidths = [
          numberColumnWidth,
          ...times(
            fieldCount,
            () => (maxWidth - numberColumnWidth) / fieldCount,
          ),
        ];

        const table = new Table({
          rows: [tableHeader, ...tableContents],
          columnWidths: columnWidths,
          // This doesn't work, but let's add it anyway
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.FIXED,
        });

        pageElements.push(table);
        const doc = new Document({
          sections: [
            {
              properties: {},
              children: pageElements,
            },
          ],
          numbering: {
            config: [
              {
                reference: UNORDERED_LIST,
                levels: [
                  {
                    level: 0,
                    format: docx.LevelFormat.BULLET,
                    text: '•',
                    style: {
                      paragraph: {
                        indent: {
                          left: 0,
                          hanging: docx.convertInchesToTwip(0.25),
                        },
                      },
                    },
                  },
                ],
              },
              {
                reference: ORDERED_LIST,
                levels: [
                  {
                    level: 0,
                    format: docx.LevelFormat.DECIMAL,
                    text: '%1',
                    style: {
                      paragraph: {
                        indent: {
                          left: 0,
                          hanging: docx.convertInchesToTwip(0.25),
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
        });

        Packer.toBlob(doc).then((blob) => resolve(blob), reject);
      })
      .catch(reject),
  );
