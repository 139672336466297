/** @prettier */
import * as React from 'react';
import Banner from '../feedback/banner/Banner';
import { OfferContext } from './OfferContext';

export const OfferBanner: React.FC = () => {
  const { offerType, bannerTitle, bannerButton, offerExpiryDate, setRole } =
    React.useContext(OfferContext);

  React.useLayoutEffect(() => {
    setRole(BoordsConfig.AccountType);
    Track.event.defer('banner_view', {
      variant: offerType,
      posthogCapture: true,
    });
  }, [offerType]);

  return !offerExpiryDate ? null : (
    <Banner
      kind={'alert'}
      hideIcon
      className="no-padding-y h-banner"
      customWidth
      message={
        <div className="flex items-center justify-start w-full pl-2">
          {bannerTitle}
          <div className="flex justify-start flex-auto">{bannerButton}</div>
        </div>
      }
    />
  );
};
