/**@prettier */

import Pluralize from 'pluralize';
import { differenceInMilliseconds } from 'date-fns';

export const calculateTimeLeft = (endDate) => {
  if (endDate === 0) {
    return 0;
  }
  const currentDateUTC = new Date().toISOString();

  const endDateUTC = new Date(`${endDate}T23:59:59Z`).toISOString();

  const difference = differenceInMilliseconds(
    new Date(endDateUTC),
    new Date(currentDateUTC),
  );
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      d: Math.floor(difference / (1000 * 60 * 60 * 24)),
      h: Math.floor((difference / (1000 * 60 * 60)) % 24),
      m: Math.floor((difference / 1000 / 60) % 60),
      s: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const minimalTimeLeft = (endDate) => {
  const remaining = calculateTimeLeft(endDate);

  if (remaining.d > 0) {
    return Pluralize('day', remaining.d, true);
  } else if (remaining.h > 0) {
    return Pluralize('hour', remaining.h, true);
  } else if (remaining.m > 0) {
    return Pluralize('minute', remaining.m, true);
  } else if (remaining.s > 0) {
    return Pluralize('second', remaining.s, true);
  } else {
    return null;
  }
};
