/**@prettier */
import { ContentfulPicture } from 'javascripts/components/shared/ContentfulPicture';
import type { SoundBite } from 'javascripts/flux/stores/testimonial';
import React from 'react';

interface TestimonialProps {
  testimonial: SoundBite;
}
const logoStyle = {
  maxWidth: 250,
  maxHeight: 70,
  width: 'auto',
  height: 'auto',
};

const Testimonial: React.FC<TestimonialProps> = (props) => {
  const { testimonial } = props;
  return (
    <div className="flex flex-col gap-8">
      <ContentfulPicture
        style={logoStyle}
        file={testimonial.company.logo.file}
        className="mx-auto"
        imgWidth={160}
        alt={testimonial.company.companyName || ''}
      />
      <h4 className="max-w-xs mx-auto text-center text-lg text-type-primary">
        {testimonial.quote}
      </h4>
      <div className="flex mx-auto items-center gap-3">
        <ContentfulPicture
          className="object-cover rounded-full border-[1.5px]"
          file={testimonial.avatar.file}
          imgWidth={28}
        />
        <span>
          {testimonial.name} | {testimonial.role}
        </span>
      </div>
    </div>
  );
};
export default Testimonial;
