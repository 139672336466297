/** @format */

import classNames from 'classnames';
import React from 'react';

interface TeamUpgradePillProps {
  linkToPricing?: boolean;
  showPlanName?: boolean;
}

export const TeamUpgradePill: React.FC<TeamUpgradePillProps> = ({
  linkToPricing = true,
  showPlanName = true,
}) => {
  const handleClick = () => {
    FlyoverActions.open.defer({ type: 'inlinePricing' });
  };

  return (
    <span
      className={classNames(
        'bg-premium-100 !text-premium-600 uppercase font-semibold rounded-sm px-1.5 py-0.5 text-xxs tracking-wider',
        linkToPricing ? 'cursor-pointer' : '',
      )}
      onClick={linkToPricing ? handleClick : undefined}
    >
      {showPlanName ? <>{`Upgrade to Workflow`}</> : <>{`Upgrade`}</>}
    </span>
  );
};
