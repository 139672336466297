/** @format */

import React from 'react';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';

interface ContextMenuItemProps {
  id: string;
  label: string;
  onItemSelect?: () => void;
  onClick?: (callback?: () => void) => void;
}

const ContextMenuItem: React.FC<ContextMenuItemProps> = (props) => {
  const { id, label, onItemSelect } = props;
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onClick && props.onClick(onItemSelect);
  };

  return (
    <div>
      <Menu.Item key={id}>
        {({ active }) => (
          <button
            onClick={handleClick}
            type="button"
            className={classNames(
              `group flex w-full  items-center  divide-x-2 rounded-md px-5 py-2 text-sm text-black`,
              { 'bg-surface-light': active },
            )}
          >
            {label}
          </button>
        )}
      </Menu.Item>
    </div>
  );
};
export default ContextMenuItem;
