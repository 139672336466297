/** @prettier */

import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';
import '../../../../javascripts/components/shared/Markdown.react.jsx';
import CommentCard from '../card/CommentCard';
import type {
  AddReplyPayloadWithFrame,
  EditCommentPayloadWithFrame,
  MarkAsCompletePayloadWithFrame,
  DeletePayloadWithFrame,
  AddReplyPayload,
  EditCommentPayload,
  MarkAsCompletePayload,
} from '../types';
import type {
  Comment,
  CommentAnnotationData,
} from '../../../../javascripts/flux/stores/comment';
import { eventSource } from 'blackbird/helpers/eventContextHelper';

interface CommentListProps {
  frameId: number;
  comments: Comment[];
  onAddReply?: (payload: AddReplyPayloadWithFrame) => void;
  onEditComment?: (payload: EditCommentPayloadWithFrame) => void;
  onMarkAsComplete?: (payload: MarkAsCompletePayloadWithFrame) => void;
  onDelete?: (payload: DeletePayloadWithFrame) => void;
  addIndent?: boolean;
  /** is this user an admin */
  canManage?: boolean;
  isReplySection?: boolean;
  hideCompletedComments?: boolean;
  isInteractive?: boolean;
}

const CommentList: FC<CommentListProps> = (props) => {
  const {
    comments = [],
    frameId,
    canManage,
    isReplySection,
    hideCompletedComments,
    isInteractive = true,
  } = props;

  const handleAddReply = (reply: AddReplyPayload) => {
    props.onAddReply && props.onAddReply({ ...reply, frameId });
  };
  const handleEditComment = (comment: EditCommentPayload) => {
    props.onEditComment && props.onEditComment({ ...comment, frameId });
  };
  const handleMarkAsComplete = (payload: MarkAsCompletePayload) => {
    props.onMarkAsComplete && props.onMarkAsComplete({ ...payload, frameId });

    Track.event.defer(`comment_status_changed`, {
      context: eventSource(undefined),
      value: payload.acknowledged,
      category: 'Product',
    });
  };
  const handleDelete = (payload: DeletePayloadWithFrame) => {
    props.onDelete && props.onDelete({ ...payload, frameId });
  };

  return (
    <>
      {comments.map((comment) => {
        return (
          <div
            key={comment.id}
            className={classNames('w-full', {
              'space-y-6': isReplySection,
            })}
          >
            <CommentCard
              id={comment.id}
              date={new Date(comment.created_at)}
              text={comment.text}
              image={comment.user.avatar}
              userName={comment.user.name}
              userId={comment.user.id}
              replies={comment.replies}
              frameId={comment.frame_id}
              isReply={Boolean(comment.in_reply_to_comment_id)}
              acknowledged={Boolean(comment.acknowledged_by)}
              onAddReply={handleAddReply}
              onEditComment={handleEditComment}
              onMarkAsComplete={handleMarkAsComplete}
              onDelete={handleDelete}
              canManage={canManage}
              hideCompletedComments={hideCompletedComments}
              isInteractive={isInteractive}
              annotation={comment.annotation ?? null}
            />
          </div>
        );
      })}
    </>
  );
};

export default CommentList;
