/** @format */

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RequestActions } from 'javascripts/flux/actions/request';
import {
  ProjectsContext,
  type Project,
  type ProjectGroup,
} from '../projects/ProjectsContext';
import logger from 'javascripts/helpers/logger';
import { apiRequest } from 'blackbird/helpers/apiRequestHelper';

export type TeamRole =
  | 'member'
  | 'manager'
  | 'supermember'
  | 'pending'
  | 'invited'
  | 'admin'
  | 'archived';

export interface TeamMember {
  id: number;
  user_id: number;
  email: string;
  name: string;
  membership_id: string;
  is_two_factor_enabled: boolean;
  is_oauth_signin: boolean;
  role: TeamRole;
  projects_with_access: string[];
  can_access_all_projects: boolean;
  avatar_url: string;
}

export interface UnregisteredInvitation {
  email: string;
  avatar_url: string;
  role: TeamRole;
  invitation_id: string;
  invitation_url: string;
  will_have_access_to_project?: number;
}

export type PuffinPlanName = 'Standard' | 'Workflow' | 'Trialing';

export interface TeamData {
  id: string;
  name: string;
  name_raw: string;
  quantity: number;
  has_role_based_access: boolean;
  has_shared_invite_link: boolean;
  has_shareable_projects: boolean;
  has_shareable_storyboards: boolean;
  has_two_factor: boolean;
  is_shared_invite_token_enabled: boolean;
  shared_invite_token: string;
  plan_name: PuffinPlanName;
  logo_url: string;
  admin: string;
  max_users: number;
  admin_domain: string;
  total_memberships_and_invitations_size: number;
  active_and_pending_members: TeamMember[];
  archived_members: TeamMember[];
  unregistered_invitations: UnregisteredInvitation[];
}

export const teamMonthlyUserCost = BoordsConfig.IsAnnual
  ? BoordsConfig.Currency === 'gbp'
    ? `£128/year`
    : BoordsConfig.Currency === 'eur'
    ? `€128/year`
    : `$160/year`
  : BoordsConfig.Currency === 'gbp'
  ? `£12/month`
  : BoordsConfig.Currency === 'eur'
  ? `€12/month`
  : `$15/month`;

export const sortTeamMembers = (members: TeamMember[]) => {
  const roleOrder: TeamRole[] = [
    'admin',
    'manager',
    'supermember',
    'member',
    'pending',
    'invited',
    'archived',
  ];

  return members.sort((a, b) => {
    const indexA = roleOrder.indexOf(a.role);
    const indexB = roleOrder.indexOf(b.role);

    if (indexA !== indexB) {
      return indexA - indexB;
    }

    // If roles are the same, sort alphabetically by name
    return a.name.localeCompare(b.name);
  });
};

interface TeamContextProps {
  activeProject: Project | null;
  activeGroup: ProjectGroup | null;
  teamData: TeamData | null;
  isLoading: boolean;
  isLoadingTeamData: boolean;
  isInviting: boolean;
  error: string | null;
  isTeamProjectModalOpen: boolean;
  setIsTeamProjectModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMembership: (
    membershipId: string,
    projectId: string,
    canAccess: boolean,
  ) => Promise<void>;
  createInvite: (
    email: string,
    role: TeamRole,
    will_have_access_to_project?: number,
  ) => Promise<void>;
  deleteInvite: (inviteId: string) => Promise<void>;
  resendInvite: (inviteId: string) => Promise<void>;
  updateMembership: (membershipId: string, role: TeamRole) => Promise<void>;
  deleteMembership: (membershipId: string) => Promise<void>;
  teamId?: string;
  sortTeamMembers: typeof sortTeamMembers;
  teamMonthlyUserCost: typeof teamMonthlyUserCost;
  isTeamFull: boolean;
  toggleSharedInviteLink: () => Promise<void>;
}

const defaultValues: TeamContextProps = {
  teamId: undefined,
  activeProject: null,
  activeGroup: null,
  teamData: null,
  isLoading: false,
  isLoadingTeamData: false,
  isInviting: false,
  error: null,
  isTeamProjectModalOpen: false,
  setIsTeamProjectModalOpen: () => {},
  toggleMembership: async () => {},
  deleteMembership: async () => {},
  createInvite: async () => {},
  deleteInvite: async () => {},
  resendInvite: async () => {},
  updateMembership: async () => {},
  sortTeamMembers: sortTeamMembers,
  teamMonthlyUserCost: teamMonthlyUserCost,
  isTeamFull: false,
  toggleSharedInviteLink: async () => {},
};

export const TeamContext = createContext<TeamContextProps>(defaultValues);

interface TeamProviderProps {
  children: React.ReactNode;
  teamId?: string;
}

export const TeamProvider: React.FC<TeamProviderProps> = ({
  children,
  teamId,
}) => {
  const { activeProject, activeGroup } = useContext(ProjectsContext);
  const [teamData, setTeamData] = useState<TeamData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTeamData, setIsLoadingTeamData] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isTeamProjectModalOpen, setIsTeamProjectModalOpen] = useState(false);

  useEffect(() => {
    const fetchTeamData = async () => {
      const effectiveTeamId = teamId || activeGroup?.team_id;
      if (effectiveTeamId) {
        setIsLoadingTeamData(true);
        setError(null);
        try {
          const response = await apiRequest({
            path: `dashboard/team/${effectiveTeamId}`,
            method: 'get',
          });
          if (!response.ok) {
            throw new Error('Failed to fetch team data');
          }
          const data: TeamData = await response.json();
          setTeamData(data);
        } catch (err) {
          logger.error('Error fetching team data:', err);
          setError('An error occurred while fetching team data');
        } finally {
          setIsLoadingTeamData(false);
        }
      }
    };
    fetchTeamData();
  }, [teamId, activeGroup]);

  const isTeamFull = useMemo(() => {
    if (!teamData) return false;

    const activeMembers = teamData.active_and_pending_members.filter((member) =>
      ['member', 'manager', 'supermember', 'admin'].includes(member.role),
    );

    return (
      activeMembers.length >= teamData.max_users &&
      ['Standard', 'Trialing'].includes(teamData.plan_name)
    );
  }, [teamData]);

  const toggleSharedInviteLink = async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!teamData) {
        throw new Error('Missing team data');
      }
      const response = await apiRequest({
        path: `teams/${teamData.id}`,
        method: 'put',
        payload: {
          is_shared_invite_token_enabled:
            !teamData.is_shared_invite_token_enabled,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to toggle shared invite link');
      }

      // Update only the is_shared_invite_token_enabled value
      setTeamData((prevTeamData) => {
        if (!prevTeamData) return null;
        return {
          ...prevTeamData,
          is_shared_invite_token_enabled:
            !prevTeamData.is_shared_invite_token_enabled,
        };
      });

      RequestActions.success.defer(
        'Shared invite link settings updated successfully',
      );
    } catch (err) {
      logger.error('Error toggling shared invite link:', err);
      setError('An error occurred while updating shared invite link settings');
      RequestActions.error.defer(
        'An error occurred while updating shared invite link settings',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMembership = async (
    membershipId: string,
    projectId: string,
    canAccess: boolean,
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await apiRequest({
        path: `dashboard/memberships/${membershipId}/toggle`,
        method: 'post',
        payload: {
          project_id: projectId,
          can_access: canAccess,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to toggle membership');
      }

      const updatedMembership: TeamMember = await response.json();

      setTeamData((prevTeamData) => {
        if (!prevTeamData) return null;

        const updatedMembers = prevTeamData.active_and_pending_members.map(
          (member) =>
            member.membership_id === membershipId ? updatedMembership : member,
        );

        return {
          ...prevTeamData,
          active_and_pending_members: updatedMembers,
        };
      });
    } catch (err) {
      logger.error('Error toggling membership:', err);
      setError('An error occurred while toggling membership');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMembership = async (membershipId: string) => {
    setIsLoading(true);
    setError(null);
    try {
      if (!teamData) {
        throw new Error('Missing team data');
      }

      const memberToDelete = teamData.archived_members.find(
        (member) => member.membership_id === membershipId,
      );

      if (!memberToDelete) {
        throw new Error('Member not found or not archived');
      }

      if (memberToDelete.role !== 'archived') {
        throw new Error('Only archived memberships can be deleted');
      }

      const response = await apiRequest({
        path: `dashboard/memberships/${membershipId}`,
        method: 'delete',
        payload: {
          team_id: teamData.id,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete membership');
      }

      setTeamData((prevTeamData) => {
        if (!prevTeamData) return null;

        const updatedArchivedMembers = prevTeamData.archived_members.filter(
          (member) => member.membership_id !== membershipId,
        );

        return {
          ...prevTeamData,
          archived_members: updatedArchivedMembers,
          total_memberships_and_invitations_size:
            prevTeamData.total_memberships_and_invitations_size - 1,
        };
      });

      RequestActions.success.defer('Team member deleted');
    } catch (err) {
      logger.error('Error deleting membership:', err);
      if (err instanceof Error) {
        setError(err.message);
        RequestActions.error.defer(err.message);
      } else {
        setError('An error occurred while removing the archived team member');
        RequestActions.error.defer(
          'An error occurred while removing the archived team member',
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createInvite = async (
    email: string,
    role: TeamRole,
    will_have_access_to_project?: number,
  ) => {
    setIsInviting(true);
    try {
      const effectiveTeamId = teamId || teamData?.id || activeGroup?.team_id;
      if (!effectiveTeamId) {
        throw new Error('No team ID available');
      }

      const payload: {
        email: string;
        role: TeamRole;
        team_id: string;
        will_have_access_to_project?: number;
      } = {
        email,
        role,
        team_id: effectiveTeamId as string,
      };

      if (will_have_access_to_project !== undefined) {
        payload.will_have_access_to_project = will_have_access_to_project;
      }

      const response = await apiRequest({
        path: 'dashboard/invites',
        method: 'post',
        payload,
      });

      if (!response.ok) {
        throw new Error('Failed to create invite');
      }

      const newInvitation: UnregisteredInvitation = await response.json();

      setTeamData((prevTeamData) => {
        if (!prevTeamData) return null;

        return {
          ...prevTeamData,
          unregistered_invitations: [
            ...prevTeamData.unregistered_invitations,
            newInvitation,
          ],
          total_memberships_and_invitations_size:
            prevTeamData.total_memberships_and_invitations_size + 1,
        };
      });

      RequestActions.success.defer('Invitation sent successfully');
    } catch (err) {
      logger.error('Error creating invite:', err);
      RequestActions.error.defer('An error occurred while creating the invite');
    } finally {
      setIsInviting(false);
    }
  };

  const deleteInvite = async (inviteId: string) => {
    setIsLoading(true);
    setError(null);
    try {
      if (!teamData) {
        throw new Error('Missing team');
      }

      const response = await apiRequest({
        path: `dashboard/invites/${inviteId}`,
        method: 'delete',
        payload: {
          team_id: teamData.id,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete invite');
      }

      setTeamData((prevTeamData) => {
        if (!prevTeamData) return null;

        const updatedInvitations = prevTeamData.unregistered_invitations.filter(
          (invite) => invite.invitation_id !== inviteId,
        );

        return {
          ...prevTeamData,
          unregistered_invitations: updatedInvitations,
          total_memberships_and_invitations_size:
            prevTeamData.total_memberships_and_invitations_size - 1,
        };
      });

      RequestActions.success.defer('Invitation deleted successfully');
    } catch (err) {
      logger.error('Error deleting invite:', err);
      setError('An error occurred while deleting the invite');
      RequestActions.error.defer('An error occurred while deleting the invite');
    } finally {
      setIsLoading(false);
    }
  };

  const resendInvite = async (inviteId: string) => {
    setIsLoading(true);
    setError(null);
    try {
      if (!teamData) {
        throw new Error('Missing team');
      }

      const response = await apiRequest({
        path: `dashboard/invites/${inviteId}/resend`,
        method: 'post',
        payload: {
          team_id: teamData.id,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to resend invite');
      }

      const data = await response.json();
      RequestActions.success.defer(data.message);
    } catch (err) {
      logger.error('Error resending invite:', err);
      setError('An error occurred while resending the invite');
      RequestActions.error.defer(
        'An error occurred while resending the invite',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const updateMembership = async (membershipId: string, role: TeamRole) => {
    setIsLoading(true);
    setError(null);
    try {
      if (!teamData) {
        throw new Error('Missing team');
      }

      const response = await apiRequest({
        path: `dashboard/memberships/${membershipId}`,
        method: 'put',
        payload: {
          team_id: teamData.id,
          role: role,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to update membership');
      }

      const updatedMembership: TeamMember = await response.json();

      setTeamData((prevTeamData) => {
        if (!prevTeamData) return null;

        const updateMemberInArray = (members: TeamMember[]) =>
          members.map((member) =>
            member.membership_id === membershipId ? updatedMembership : member,
          );

        const updatedActiveMembers = updateMemberInArray(
          prevTeamData.active_and_pending_members,
        );
        const updatedArchivedMembers = updateMemberInArray(
          prevTeamData.archived_members,
        );

        // If the role changed to or from 'archived', move the member between arrays
        const isNowArchived = updatedMembership.role === 'archived';
        const wasArchived = prevTeamData.archived_members.some(
          (member) => member.membership_id === membershipId,
        );

        let finalActiveMembers = updatedActiveMembers;
        let finalArchivedMembers = updatedArchivedMembers;

        if (isNowArchived && !wasArchived) {
          finalActiveMembers = finalActiveMembers.filter(
            (member) => member.membership_id !== membershipId,
          );
          finalArchivedMembers = [...finalArchivedMembers, updatedMembership];
        } else if (!isNowArchived && wasArchived) {
          finalArchivedMembers = finalArchivedMembers.filter(
            (member) => member.membership_id !== membershipId,
          );
          finalActiveMembers = [...finalActiveMembers, updatedMembership];
        }

        return {
          ...prevTeamData,
          active_and_pending_members: finalActiveMembers,
          archived_members: finalArchivedMembers,
        };
      });

      RequestActions.success.defer('Membership updated successfully');
    } catch (err) {
      logger.error('Error updating membership:', err);
      setError('An error occurred while updating the membership');
      RequestActions.error.defer(
        'An error occurred while updating the membership',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const value: TeamContextProps = {
    teamId,
    resendInvite,
    deleteInvite,
    activeProject,
    activeGroup,
    teamData,
    isLoading,
    isLoadingTeamData,
    isInviting,
    error,
    isTeamProjectModalOpen,
    setIsTeamProjectModalOpen,
    toggleMembership,
    createInvite,
    updateMembership,
    sortTeamMembers,
    isTeamFull,
    toggleSharedInviteLink,
    teamMonthlyUserCost,
    deleteMembership,
  };

  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>;
};
