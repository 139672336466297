/** @prettier */
import * as React from 'react';
import { parseISO } from 'date-fns';
import Banner from '../feedback/banner/Banner';
import { GiftIcon } from '@heroicons/react/20/solid';
import Button from '../button/Button';
import { SaleTimer } from 'javascripts/components/shared/CountdownTimer';
import { SaleContext } from './SaleContext';

export const SaleBanner: React.FC = () => {
  const { activeSale } = React.useContext(SaleContext);
  // React.useLayoutEffect(() => {
  //   Track.event.defer('banner_view', {
  //     variant: variant,
  //     posthogCapture: true,
  //   });
  // }, [variant]);

  if (!activeSale) {
    return null;
  }

  const BannerCopy: React.FC = () => (
    <div className="flex items-center flex-auto">
      <div className="flex items-center pl-3 pr-1.5 pt-1 pb-1.5 leading-tight font-semibold rounded-md bg-brand-yellow/70 -ml-2">
        <span>{activeSale.bannerBadgeIcon}</span>
        <span className="ml-1.5 mr-1 text-black/70">
          {activeSale.bannerBadgeText}
        </span>
      </div>

      <div className="ml-3 mr-3 font-semibold space-x-1.5">
        <span>{activeSale.bannerDescription}</span>
        <span>{`→`}</span>
      </div>

      <div className="flex justify-end flex-auto lg:justify-start">
        <Button type="pink" size="sm" rounded>
          <span>{`Compare Plans`}</span>
        </Button>
      </div>

      <span className="inline-block pl-1.5 rounded-md bg-white/30 text-brand-pink pb-0.5">
        <span className="mr-1 font-semibold">{`Offer ends in`}</span>
        <SaleTimer endDate={parseISO(activeSale.endDate)} />
      </span>
    </div>
  );

  return (
    <Banner
      kind={'alert'}
      hideIcon
      className="no-padding-y h-banner"
      customWidth
      message={
        <a
          onClick={() => {
            // Track.event.defer('banner_click', {
            //   category: 'Checkout',
            //   variant: variant,
            // });
            FlyoverActions.open.defer({ type: 'inlinePricing' });
          }}
          className="flex items-center justify-start w-full pl-2 cursor-pointer"
        >
          <BannerCopy />
        </a>
      }
    />
  );
};
