/*** @prettier */
import React from 'react';
import Dialog from 'blackbird/components/dialog/Dialog';
import { type ButtonProps } from 'blackbird/components/button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  status: string;
  plan: string;
  expired_trial?: boolean;
}

const PaymentLockout: React.FC<Props> = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: props.expired_trial
      ? 'modals.expiredTrialLockout'
      : 'modals.paymentLockout',
  });

  const confirmBtnProps = {
    type: 'fancy',
  };

  return (
    <Dialog
      isOpen
      isBlur
      confirmText={t('cta.label')}
      onConfirmBtnClick={() => {
        window.location.href = t('cta.href');
      }}
      actionChildren={
        <div className="ml-2 text-sm space-x-4 text-type-subdued">
          <a className="hover:underline" href={t('account.href')}>
            {t('account.label')}
          </a>
        </div>
      }
      keepOpen
      confirmBtnProps={confirmBtnProps as ButtonProps}
      alignButtons="left"
      hideDescription
      hideClose
      hideCancel
      title={t('title')}
      subtitle={t('subtitle', { planName: props.plan, status: props.status })}
    />
  );
};

(window as any).PaymentLockout = PaymentLockout;
