import { retryWhile } from 'javascripts/helpers/retry-while';
import logger from '../../helpers/logger';

const _ = require('underscore');
require('../actions/intercom');
const { onLoadAfterBackButton } = require('../../helpers/onLoadAfterBackButton')
const { idleTimeout } = require('../../helpers/idle-timeout');

(() => {
  class IntercomStore {

    constructor() {
      // We default this to undefined, so we know when we don't have a value yet
      this.isVisible = undefined;
      this.canShowIntercom = false;

      // keep a list of current 'contexts' in which we don't want to show the
      // intercom overlay. As long as one context is active, we will hide this.
      // Normally I'd put this in a generic UI store, but we don't have that.
      this.currentHiddenContexts = []

      this.bindListeners({
        handleMessage:     IntercomActions.MESSAGE,
        enterContext:      IntercomActions.ENTER_CONTEXT,
        leaveContext:      IntercomActions.LEAVE_CONTEXT,
      });

      const checkForIntercomLoad = () => {
        const isEnabled = typeof Intercom !== 'undefined' && !BoordsConfig.shouldHideIntercom
        this.canShowIntercom = isEnabled;
      }

      window.addEventListener('load', idleTimeout(() => {
        checkForIntercomLoad()
        this.evaluateHideOrShow()

        retryWhile(() => typeof Intercom !== 'undefined', () => {
          checkForIntercomLoad()
          this.evaluateHideOrShow()
          if (!this.canShowIntercom) logger.info('Intercom is disabled for this context or user, or it could not be found.')
        }, { maxTries: 20 })

        this.emitChange()
      }))

      onLoadAfterBackButton(() => this.evaluateHideOrShow())
    }

    handleMessage(options) {
      if (this.isVisible) {
        ajaxHelper.ajax({
          method: "post",
          url: "/intercom_message.json",
          data: {
            message_type: options.type,
          },
          success: function (response) {
            if (!_.isNull(response)) {
              setTimeout(function() {
                Intercom("update", {last_request_at: parseInt((new Date()).getTime()/1000)})
              }, 2000);
            }
          }.bind(this),
          error: function(response) {
            logger.error(response);
          }.bind(this),
        });
      }
    }

    evaluateHideOrShow() {
      if (typeof Intercom === 'undefined') return;

      const shouldShow = this.currentHiddenContexts.length === 0
        && this.canShowIntercom

      if (shouldShow && this.isVisible === false) {
        idleTimeout(() => {
          Intercom("update", { hide_default_launcher: false })
        }, 500)
      } else if (!shouldShow) {
        // Even if we're not currently visible, we send intercom the hide
        // command again, because in some cases (when the page loads in
        // the editor) this is necessary
        setTimeout(() => Intercom("update", { hide_default_launcher: true }),0)
      }

      this.isVisible = shouldShow
    }

    enterContext(context) {
      this.currentHiddenContexts.push(context)
      this.evaluateHideOrShow()
    }

    leaveContext(context) {
      this.currentHiddenContexts = this.currentHiddenContexts.filter(i => i !== context)
      this.evaluateHideOrShow()
    }

  }

  window.IntercomStore = alt.createStore(IntercomStore, 'IntercomStore');

})();
