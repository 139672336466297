/** @format */

import Icon from '../icon/Icon';
import ProjectIcon from '../../images/icons/project.svg';
import React from 'react';
import { ProjectsContext, type Project } from './ProjectsContext';
import { EditedDate } from './ProjectStoryboard';
import classNames from 'classnames';

interface ProjectSubProjectProps {
  project: Project;
}
export const ProjectSubProject: React.FC<ProjectSubProjectProps> = ({
  project,
}) => {
  const {
    activeGroup,
    selectedProjects,
    toggleProjectSelection,
    setActiveProject,
  } = React.useContext(ProjectsContext);

  const isSelected = selectedProjects.includes(project.id);

  const canMoveProject = activeGroup?.actions.includes('projects.move');
  const canDeleteProject = activeGroup?.actions.includes('projects.delete');

  return (
    <div
      className={classNames(
        'rounded-lg p-1.5',
        canMoveProject || canDeleteProject
          ? 'hover:bg-black/10'
          : 'cursor-pointer',
        isSelected && 'ring ring-2 ring-brand-pink cursor-pointer',
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isSelected) {
          setActiveProject(project);
        } else {
          if (canMoveProject || canDeleteProject) {
            toggleProjectSelection(project.id);
          } else {
            setActiveProject(project);
          }
        }
      }}
    >
      <div className="p-4 pt-3 bg-white border border-border rounded-md group">
        <div className="flex items-center mb-0.5">
          <div className="flex-shrink-0 mr-1.5">
            <div className="flex items-center opacity-20 group-hover:opacity-50">
              <Icon
                icon={<ProjectIcon />}
                className="flex-shrink-0 h-5 mt-0.5"
                color="black"
              />
            </div>
          </div>
          <div className="flex-auto">
            <div
              className="inline-block text-base cursor-pointer hover:underline"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActiveProject(project);
              }}
            >
              {project.name}
            </div>
          </div>
        </div>

        <EditedDate editedAt={project.updated_at} />
      </div>
    </div>
  );
};
