/** @prettier */
import { BannerEdu } from 'javascripts/components/banner/BannerEdu';
import { BannerFreemiumControl } from 'javascripts/components/banner/BannerFreemiumControl';
import { DefaultStoreContextProvider } from 'javascripts/flux/DefaultStoreContextProvider';
import * as React from 'react';
import { SaleBanner } from '../sale/SaleBanner';
import SaleProvider, { SaleContext } from '../sale/SaleContext';
import { OfferBanner } from './OfferBanner';
import { OfferContext, OfferProvider } from './OfferContext';

const OfferFreemiumBannerComponent: React.FC = () => {
  const { offerExpiryDate } = React.useContext(OfferContext);
  const { activeSale } = React.useContext(SaleContext);

  return BoordsConfig.IsEdu ? (
    <BannerEdu />
  ) : activeSale ? (
    <SaleBanner />
  ) : offerExpiryDate ? (
    <OfferBanner />
  ) : (
    <BannerFreemiumControl variant="freemium_expired" />
  );
};

export const OfferFreemiumBannerContainer: React.FC = () => (
  <DefaultStoreContextProvider>
    <SaleProvider>
      <OfferProvider>
        <OfferFreemiumBannerComponent />
      </OfferProvider>
    </SaleProvider>
  </DefaultStoreContextProvider>
);
