/** @format */

import React, { type ReactElement } from 'react';
import { CountdownTimer } from './CountdownTimer';

interface CountdownPillProps {
  endDate: Date | string;
  hideDescription?: boolean;
}

export const CountdownPill: React.FC<CountdownPillProps> = ({
  endDate,
  hideDescription,
}): ReactElement => (
  <div className="inline-block px-2 py-1 text-sm bg-brand-pink-25 rounded-md text-brand-pink space-x-1">
    {!hideDescription && <span>{`Offer ends in`}</span>}
    <CountdownTimer endDate={endDate} />
  </div>
);
