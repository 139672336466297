/** @prettier */
/* eslint-disable no-misleading-character-class */
/** From https://github.com/xpl/printable-characters/ */
const zeroWidthCharacterExceptNewline =
  '\u0000-\u0008\u000B-\u0019\u001b\u009b\u00ad\u200b\u2028\u2029\ufeff\ufe00-\ufe0f';

export const removeUnsupportedCharacters = (str: string) =>
  str
    .replace(/\v/g, '')
    .replace(new RegExp(`[${zeroWidthCharacterExceptNewline}]`, 'g'), '');
