/** @format */

import React, { useContext } from 'react';
import classNames from 'classnames';
import { ProjectsContext } from './ProjectsContext';
import { ProjectSkeletonAccordion } from './ProjectSkeleton';
import { ProjectGroupComponent } from './ProjectGroupComponent';

export const ProjectsAccordion: React.FC<{ isSelectionMode?: boolean }> = ({
  isSelectionMode,
}) => {
  const { projectGroups, isLoading, error } = useContext(ProjectsContext);

  if (isLoading) {
    return <ProjectSkeletonAccordion />;
  }

  if (error) {
    return <div className="py-4 text-center text-red-600">{error}</div>;
  }

  if (!projectGroups) {
    return <div className="py-4 text-center">No project groups found.</div>;
  }

  return (
    <>
      <div
        className={classNames(
          'p-3 pb-5 overflow-y-auto space-y-4 ',
          isSelectionMode ? 'max-h-[25.5rem]' : 'flex-auto',
        )}
      >
        <ProjectGroupComponent
          isSelectionMode={isSelectionMode}
          group={projectGroups.team}
        />
        {projectGroups.memberships.map((group, index) => {
          // Don't show team project destinations
          // when in selection mode if permissions aren't sufficient
          if (isSelectionMode && !group.actions.includes(`projects.create`)) {
            return null;
          }

          return (
            <ProjectGroupComponent
              isSelectionMode={isSelectionMode}
              key={index}
              group={group}
            />
          );
        })}
      </div>
    </>
  );
};
