/** @prettier */
import { NativeTypes } from 'react-dnd-html5-backend';
import { contains } from 'underscore';
import type { frameEditorShapeTypes } from '../frame_editor/shapes';

export const DRAGGABLE_TYPES = {
  frame: 'frameAsset',
  unsplash: 'unsplashItem',
  image: 'imageAsset',
  iconfinder: 'iconFinderItem',
  frameEditorShape: 'frameEditorShape',
} as const;

const imageLibraryTypes = [
  DRAGGABLE_TYPES.frame,
  DRAGGABLE_TYPES.image,
  DRAGGABLE_TYPES.unsplash,
  DRAGGABLE_TYPES.iconfinder,
];

export type fileDropResult = {
  files: File[];
  items: DataTransferItemList | null;
};

export type imageLibraryDragItem = {
  id: string;
  image: string;
};

export type frameEditorShapeDragItem = {
  type: frameEditorShapeTypes;
};

export const isFileDropResult = (type, item): item is fileDropResult =>
  type === NativeTypes.FILE;

export const isImageLibraryDropResult = (
  type,
  item,
): item is imageLibraryDragItem => contains(imageLibraryTypes, type);

export const isFrameEditorShapeDropResult = (
  type,
  item,
): item is frameEditorShapeDragItem =>
  type === DRAGGABLE_TYPES.frameEditorShape;

export const allAcceptedDropTypes = [
  NativeTypes.FILE,
  ...imageLibraryTypes,
] as const;
