/** @format */

import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import RichTextInput from '../form/richTextInput/RichTextInput';
import { ProjectsContext } from './ProjectsContext';

export const ProjectContentsDescripton: React.FC = () => {
  const DEFAULT_PLACEHOLDER = `Click here to add a description`;

  const { activeProject, updateProject, activeGroup } =
    useContext(ProjectsContext);
  const [placeholder, setPlaceholder] = React.useState(DEFAULT_PLACEHOLDER);

  const canRename = activeGroup?.actions.includes('projects.rename');

  useEffect(() => {
    if (!canRename) {
      setPlaceholder(``);
    }
  }, [canRename]);

  if (!activeProject || !activeGroup) {
    return null;
  }

  return (
    <div className={classNames(activeProject.description && 'mb-6')}>
      <RichTextInput
        disabled={!canRename}
        immediate={false}
        autoFocus={false}
        value={activeProject.description || ''}
        onChange={(newValue: string) => {
          updateProject(activeProject.id, {
            description: newValue,
          });
        }}
        onFocus={() => setPlaceholder(``)}
        onBlur={() => setPlaceholder(DEFAULT_PLACEHOLDER)}
        className={
          'min-h-[1.5rem] placeholder-text-type-disabled cursor-pointer focus:ring-form-active ring-white ring-1 max-w-2xl rounded-sm px-1  -ml-1 prose'
        }
        placeholder={placeholder}
      />
    </div>
  );
};
