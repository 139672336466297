/**@prettier */
import React, { useContext } from 'react';
import Logo from 'blackbird/components/logo/Logo';
import MoveHorizontal from 'blackbird/images/icons/move-horizontal.svg';

import Sparkle from 'blackbird/images/icons/sparkle.svg';
import type { FeedbackKind } from 'blackbird/components/feedback/types';
import SignupScreen from './SignupScreen';
import SignInScreen from './SignInScreen';
import FinishOverboardingScreen from './FinishOverboarding';
import ProjectScreen from './ProjectScreen';
import TeamNameScreen from './TeamNameScreen';
import ResetPasswordForm from './ResetPassword';
import WelcomeScreen from './WelcomeScreen';
import ChangePasswordScreen from './ChangePassword';
import Testimonial from './Testimonial';
import EnterCC from './EnterCC';
import type { SoundBite } from 'javascripts/flux/stores/testimonial';
import './styles.css';
import AddTaxId from './AddTaxId';
import Button from 'blackbird/components/button/Button';
import { SubdomainProvider } from 'blackbird/helpers/contexts/SubdomainContext';
import { SignupContext } from 'blackbird/helpers/contexts/SignupContext';
import { SignupWithPlanProgress } from './SignUpWithPlanProgress';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { SignupWithPlanPreview } from './SignUpWithPlanPreview';
import { SparkleGraphic } from './SparkleGraphic';

export enum OnboardingType {
  SIGN_IN = 'signin',
  SIGN_UP = 'signup',
  SIGN_UP_EMAIL = 'signup_email',
  WELCOME = 'welcome',
  NAME_PROJECT = 'name_project',
  NAME_TEAM = 'name_team',
  ENTER_CC = 'enter_cc',
  TAX_ID = 'tax_id',
  FINISHING = 'finishing',
  RESET_PASSWORD = 'reset_password',
  CHANGE_PASSWORD = 'change_password',
}

export interface OnboardingProps {
  type: OnboardingType;
  notification?: {
    kind: FeedbackKind;
    message: string;
  };
  testimonial?: SoundBite;
  projectName?: string;
  projectId?: number;
  username?: string;
  hideSideGraphic?: boolean;
  authenticityToken?: string;
  formAction?: string;
  onToggleScreen?: (type: OnboardingType) => void;
  googleSigninPath?: string;
  onSignIn?: ({ email, password }: { email: string; password: string }) => void;
  onResetPassword?: (email: string) => void;
  onSignUp?: ({
    email,
    password,
    name,
  }: {
    email: string;
    password: string;
    name: string;
  }) => void;
  onSurveyComplete?: (role?: string) => void;
  onSaveTeamName?: () => void;
  onNameSkip?: () => void;
  onProjectSetup?: (projectName: string) => void;
  onEnterCC?: (projectName: string) => void;
  onChangePassword?: ({
    password,
    confirmedPass,
  }: {
    password: string;
    confirmedPass: string;
  }) => void;
  passwordResetToken?: string;
  inviteToken?: string;
  invitedEmail?: string;
  upgradeProps?: Record<string, any>;
  upgradeState?: Record<string, any>;
  planType?: string;
}

export interface ITeamNameProps {
  name?: string;
  subdomain?: string;
}

const TeamNameContainer: React.FC<OnboardingProps> = (props) => (
  <SubdomainProvider team_id={BoordsConfig.TeamId}>
    <TeamNameScreen {...props} />
  </SubdomainProvider>
);

const ComponentMap: Record<OnboardingType, React.FC<any>> = {
  [OnboardingType.SIGN_UP]: SignupScreen,
  [OnboardingType.SIGN_IN]: SignInScreen,
  [OnboardingType.SIGN_UP_EMAIL]: SignupScreen,
  [OnboardingType.RESET_PASSWORD]: ResetPasswordForm,
  [OnboardingType.ENTER_CC]: EnterCC,
  [OnboardingType.TAX_ID]: AddTaxId,
  [OnboardingType.FINISHING]: FinishOverboardingScreen,
  [OnboardingType.NAME_PROJECT]: ProjectScreen,
  [OnboardingType.NAME_TEAM]: TeamNameContainer,
  [OnboardingType.WELCOME]: WelcomeScreen,
  [OnboardingType.CHANGE_PASSWORD]: ChangePasswordScreen,
};

const Onboarding: React.FC<OnboardingProps> = (props) => {
  const { type, testimonial, hideSideGraphic } = props;
  const handleLinkClick = () => {
    props.onToggleScreen &&
      props.onToggleScreen(
        type === OnboardingType.SIGN_IN
          ? OnboardingType.SIGN_UP
          : OnboardingType.SIGN_IN,
      );
  };

  const { signupWithPlan } = useContext(SignupContext);
  const FormComponent = ComponentMap[type];

  return (
    <div className="relative flex min-h-vp">
      {![OnboardingType.SIGN_UP, OnboardingType.SIGN_UP_EMAIL].includes(
        type,
      ) && (
        <>
          {!hideSideGraphic ? (
            <div className="relative flex-shrink-0 overflow-hidden">
              <div className="onboarding-graphic w-[496px]  h-full hidden lg:flex lg:flex-col justify-center">
                {signupWithPlan ? (
                  <SignupWithPlanPreview />
                ) : testimonial ? (
                  <>
                    <Testimonial testimonial={testimonial} />
                    <Sparkle className="absolute right-14 top-14" />
                    <MoveHorizontal className="absolute flex-shrink-0 bottom-16 -translate-x-14" />
                  </>
                ) : (
                  <SparkleGraphic />
                )}

                <Logo className="absolute z-10 w-12 h-12 top-6 left-6" />
              </div>
            </div>
          ) : (
            <Logo className="absolute z-10 w-12 h-12 top-6 left-6" />
          )}
        </>
      )}
      <div className="flex flex-col flex-grow p-8 bg-white">
        {signupWithPlan && (
          <div className="flex justify-center">
            <div className="flex items-center justify-start w-full max-w-md pr-12 mt-2 ml-6 space-x-4">
              <SignupWithPlanProgress stage={1} label={`Create Account`} />
              <div className="flex items-center justify-center flex-auto">
                <div className="w-20 -mr-[0.9rem] border-b-2 border-black" />
                <ChevronRightIcon className="h-6" />
              </div>
              <SignupWithPlanProgress
                stage={2}
                label={`Make Payment`}
                inactive
              />
            </div>
          </div>
        )}
        {type === OnboardingType.SIGN_IN && (
          <div className="flex items-center justify-end space-x-4">
            <span>{`New to Boords?`}</span>
            <Button
              rounded
              type={'solid'}
              onClick={handleLinkClick}
              className="font-bold cursor-pointer"
            >
              {`Sign Up`}
            </Button>
          </div>
        )}
        <FormComponent {...props} />
      </div>
    </div>
  );
};

export default Onboarding;
