/** @format */

import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  CheckIcon,
  FolderIcon,
  FolderOpenIcon,
  FolderClosedIcon,
} from 'lucide-react';
import { ProjectsContext, type Project } from './ProjectsContext';
import { ProjectsPlusButton } from './ProjectsPlusButton';
import Tooltip from '../feedback/tooltip/Tooltip';

interface ProjectsAccordionItemProps {
  project: Project;
  isSelectionMode?: boolean;
  isParentDisabled?: boolean;
  level: number;
}

export const ProjectsAccordionItem: React.FC<ProjectsAccordionItemProps> = ({
  project,
  isSelectionMode,
  isParentDisabled,
  level,
}) => {
  const {
    setActiveProject,
    activeProject,
    setNewProjectParent,
    newProjectParent,
    setActiveGroup,
    activeGroup,
    projectGroups,
    expandedProjectIds,
    isolatedExpandedProjectIds,
    toggleProjectExpansion,
    disabledMoveTargetProjectIds,
    disabledMoveTargetParentIds,
    selectedProjects,
    selectedStoryboards,
  } = useContext(ProjectsContext);

  // Is the project itself disabled?
  const isDisabled =
    disabledMoveTargetProjectIds?.has(project.id) && isSelectionMode;

  const isProjectOrParentDisabled = isDisabled || isParentDisabled;

  const isChildDisabled =
    disabledMoveTargetParentIds?.has(project.id) && isSelectionMode;

  const isActive = activeProject?.id === project.id;
  const isSelectedParent =
    newProjectParent?.id === project.id && isSelectionMode;
  const hasChildren = project.children && project.children.length > 0;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleProjectExpansion(project.id, isSelectionMode);
  };

  const isExpanded = isSelectionMode
    ? isolatedExpandedProjectIds.has(project.id)
    : expandedProjectIds.has(project.id);

  const handleProjectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isProjectOrParentDisabled && !isChildDisabled) {
      if (!isSelectionMode) {
        setActiveProject(project);
      }
      setNewProjectParent(project);
      // Find and set the active group
      if (projectGroups) {
        const allGroups = [projectGroups.team, ...projectGroups.memberships];
        const group = allGroups.find((g) =>
          g.projects.some((p) => p.id === project.id),
        );
        if (group) {
          setActiveGroup(group);
        }
      }
    }
  };

  return (
    <>
      <Tooltip
        title={
          isChildDisabled &&
          selectedStoryboards.length > 0 &&
          selectedProjects.length === 0
            ? `You can't move a storyboard into it's current project`
            : isParentDisabled
            ? `You can't move a project into one of it's sub-projects`
            : isDisabled
            ? `You can't move a project into itself`
            : isChildDisabled
            ? `You can't move a project into it's current parent`
            : ''
        }
        placement="top"
        disabled={!isProjectOrParentDisabled && !isChildDisabled}
      >
        <div
          className={classNames(
            'pr-3 py-1.5 rounded-md mb-0.5 group',
            isProjectOrParentDisabled || isChildDisabled // Disabled
              ? 'bg-white cursor-not-allowed opacity-40'
              : isActive && !isSelectionMode
              ? 'bg-surface'
              : isSelectedParent
              ? 'bg-brand-blue-25'
              : 'bg-white hover:bg-surface cursor-pointer',
          )}
          style={{ paddingLeft: `${1 + level * 0.5}rem` }}
          onClick={handleProjectClick}
        >
          <div className="flex items-center">
            {!hasChildren ? (
              // Last child folder icon
              <div className="flex-shrink-0 mr-1.5 px-0.5 py-0.5 cursor-pointer ">
                <FolderIcon className="w-4 h-4" />
              </div>
            ) : (
              // Open/close folder icons
              <div
                className="flex-shrink-0 mr-1.5 px-0.5 py-0.5 cursor-pointer rounded-sm hover:bg-surface-add_frame"
                onClick={handleToggle}
              >
                {isExpanded ? (
                  <FolderOpenIcon className="w-4 h-4" />
                ) : (
                  <FolderClosedIcon className="w-4 h-4" />
                )}
              </div>
            )}

            {/* Project name */}
            <span className={classNames('flex-auto block text-sm truncate')}>
              {project.name}
            </span>

            {/* Add project button */}
            {!isSelectionMode && activeGroup && (
              <div className={classNames('opacity-0 group-hover:opacity-100')}>
                <ProjectsPlusButton project={project} group={activeGroup} />
              </div>
            )}

            {/* Checkmark in selection mode */}
            {isSelectionMode && isSelectedParent && (
              <CheckIcon className="w-4 h-4 text-brand-blue" />
            )}
          </div>
          {/* {isExpanded && !hasChildren && ( */}
          {/*   <div className="hidden pl-0.5 ml-6 text-xs text-black/50 mt-0.5 mb-1">{`Create sub-project`}</div> */}
          {/* )} */}
        </div>
      </Tooltip>

      {/* Sub-projects */}
      {isExpanded &&
        project.children &&
        project.children.map((child) => (
          <ProjectsAccordionItem
            key={child.id}
            project={child}
            isSelectionMode={isSelectionMode}
            level={level + 1}
            isParentDisabled={isProjectOrParentDisabled}
          />
        ))}
    </>
  );
};
