/** @format */

import React, { useState, useCallback } from 'react';
import {
  Trash2,
  Move,
  EllipsisIcon,
  type LucideIcon,
  Copy,
  Link,
} from 'lucide-react';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import Panel from '../../panel/Panel';
import { type ProjectDocument, ProjectsContext } from '../ProjectsContext';
import { TeamUpgradePill } from '../../team/TeamUpgradePill';
import { RequestActions } from 'javascripts/flux/actions/request';

interface Action {
  label: string;
  icon: LucideIcon;
  onClick: () => void;
  disabled?: boolean;
}

interface ProjectStoryboardActionsProps {
  document: ProjectDocument;
}

export const ProjectStoryboardActions: React.FC<
  ProjectStoryboardActionsProps
> = ({ document }) => {
  const {
    setIsBulkMoveModalOpen,
    setSelectedStoryboards,
    archiveItems,
    activeGroup,
    duplicateStoryboard,
    setIsSingleStoryboardMove,
  } = React.useContext(ProjectsContext);

  const [selectedAction, setSelectedAction] = useState<Action | null>(null);

  const handleDuplicate = useCallback(() => {
    duplicateStoryboard(document.short_slug);
  }, [document, duplicateStoryboard]);

  const handleDelete = useCallback(() => {
    if (confirm('Are you sure you want to delete this storyboard?')) {
      archiveItems({ storyboardIds: [document.id] });
    }
  }, [document, archiveItems]);

  const handleMove = useCallback(() => {
    setSelectedStoryboards([document.id]);
    setIsSingleStoryboardMove(true);
    setIsBulkMoveModalOpen(true);
  }, [
    document,
    setSelectedStoryboards,
    setIsSingleStoryboardMove,
    setIsBulkMoveModalOpen,
  ]);

  const handleCopyLink = useCallback(() => {
    const link = `${window.location.origin}/s/${document.short_slug}`;
    navigator.clipboard.writeText(link).then(() => {
      RequestActions.success.defer(`Public link copied`);
    });
  }, [document]);

  const canMoveStoryboard = activeGroup?.actions.includes('storyboards.move');
  const canDeleteStoryboard =
    activeGroup?.actions.includes('storyboards.delete');
  const canCreateStoryboard =
    activeGroup?.actions.includes('storyboards.create');
  const isTrialing = activeGroup?.plan_name === 'Trialing';
  const isStandard = activeGroup?.plan_name === 'Standard';
  const canCopyLink = !(isTrialing || isStandard);

  const options: Action[] = React.useMemo(() => {
    const availableOptions: Action[] = [
      {
        label: 'Public link',
        icon: Link,
        onClick: handleCopyLink,
        disabled: !canCopyLink,
      },
    ];
    if (canCreateStoryboard) {
      availableOptions.push({
        label: 'Duplicate',
        icon: Copy,
        onClick: handleDuplicate,
        disabled: isTrialing,
      });
    }
    if (canMoveStoryboard) {
      availableOptions.push({
        label: 'Move to',
        icon: Move,
        onClick: handleMove,
      });
    }
    if (canDeleteStoryboard) {
      availableOptions.push({
        label: 'Delete',
        icon: Trash2,
        onClick: handleDelete,
      });
    }
    return availableOptions;
  }, [
    canMoveStoryboard,
    canDeleteStoryboard,
    handleMove,
    handleDelete,
    canCreateStoryboard,
    isTrialing,
    handleDuplicate,
    canCopyLink,
    handleCopyLink,
  ]);

  const handleChange = useCallback(
    (action: Action | null) => {
      setSelectedAction(action);
      if (action && !action.disabled) {
        action.onClick();
      }
      setIsSingleStoryboardMove(false);
    },
    [setIsSingleStoryboardMove],
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <Listbox
      as="div"
      className="relative group/storyboardactions"
      value={selectedAction}
      onChange={handleChange}
    >
      <Listbox.Button className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-white outline-none cursor-pointer rounded-md ring-none bg-opacity-80 hover:bg-opacity-100 transition-opacity">
        <EllipsisIcon className="w-4 h-4" />
      </Listbox.Button>
      <Listbox.Options
        as={Panel}
        className="absolute left-0 flex-col w-40 mt-2 border shadow-md rounded-md border-border top-full focus:outline-none"
      >
        <div className="p-2 space-y-1">
          {options.map((option) => (
            <Listbox.Option
              key={option.label}
              value={option}
              disabled={option.disabled}
              as="div"
              className={({ active, disabled }) =>
                classNames(
                  'cursor-pointer select-none relative pb-2 pt-1.5 px-2 rounded-md ',
                  active && !disabled ? 'bg-surface-light' : '',
                  disabled ? 'opacity-50 cursor-not-allowed' : '',
                )
              }
            >
              {({ active, disabled }) => (
                <>
                  <div className="flex items-center space-x-2">
                    <option.icon className="flex-shrink-0 h-4 w-4 pt-0.5" />
                    <span className="flex-auto text-sm">{option.label}</span>
                  </div>
                  {disabled && (
                    <div className="whitespace-nowrap">
                      <TeamUpgradePill showPlanName={false} />
                    </div>
                  )}
                </>
              )}
            </Listbox.Option>
          ))}
        </div>
      </Listbox.Options>
    </Listbox>
  );
};
