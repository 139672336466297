/** @prettier */

import type { FC } from 'react';
import React, { useState, useMemo, useRef } from 'react';
import classNames from 'classnames';
import Avatar from '../../avatar/Avatar';
import { RelativeTime } from '../../relativeTime/RelativeTime';
import '../../../../javascripts/components/shared/Markdown.react.jsx';
import ReplyIcon from '../../../images/icons/reply.svg';
import CheckCircle from '../../../images/icons/checkmark.svg';
import CheckCircleSolid from 'blackbird/images/icons/checkmark-solid.svg';
import Icon from '../../icon/Icon';
import CommentEditor, {
  type CommentEditorSubmithandler,
} from '../editor/CommentEditor';
import { useToggle } from '../../../helpers/hooks/useToggle';
import CommentList from '../list/CommentList';
import MoreOptions from '../../menu/MoreOptions';
import type {
  Comment,
  CommentAnnotationData,
} from '../../../../javascripts/flux/stores/comment';
import type {
  AddReplyPayload,
  EditCommentPayload,
  MarkAsCompletePayload,
  DeletePayload,
} from '../types';
import { CommentActionsType } from '../types';
import { useConfirmDialog } from '../../dialog/hooks';
import logger from 'javascripts/helpers/logger';
import { useTranslation } from 'react-i18next';
import Button from 'blackbird/components/button/Button';
import { usePersistedBoolean } from 'blackbird/helpers/hooks/useBoolean';
import { getFirstCharacter } from 'javascripts/helpers/get-first-character';
import {
  commentNameLocalState,
  editableCommentsLocalState,
} from 'javascripts/helpers/local-state';
import { ConditionalTooltip } from 'blackbird/components/feedback/tooltip/Tooltip';
import { contains, filter } from 'underscore';
import { useStore } from 'javascripts/helpers/useStore';
import type { UserStore } from 'javascripts/flux/stores/user';
import { notNullOrUndefined } from 'javascripts/helpers/notUndefined';
import type { AnnotationToolEngineProps } from '../annotations/AnnotationToolEngine';
import { type CommentAnnotationsStore } from 'javascripts/flux/stores/commentAnnotations';
import { CommentAnnotationsActions } from 'javascripts/flux/actions/commentAnnotations';
import { AnnotationToggle } from '../annotations/AnnotationToggle';

interface CommentCardProps {
  id: number;
  frameId: number;
  className?: string;
  text: string;
  userId?: number;
  userName: string;
  date: Date;
  /** when no image URL is passed, we will show this initial. Defaults to the
   * first letter of `userName` */
  initial?: string;
  image?: string;
  /**
   * By default actions are rendered using absolute positioning. It could lead to overlap with the name component.
   * Using this props we can disable that behavior and actions will render as a
   * separate div
   */
  disableActionsOverlap?: boolean;
  replies?: Comment[];
  isReply?: boolean;
  onAddReply?: (value: AddReplyPayload) => void;
  onEditComment?: (payload: EditCommentPayload) => void;
  onMarkAsComplete?: (payload: MarkAsCompletePayload) => void;
  onDelete?: (payload: DeletePayload) => void;
  acknowledged?: boolean;
  /** can the current user "manage" comments? i.e. are they an admin */
  canManage?: boolean;
  hideCompletedComments?: boolean;
  /* There are cases (like in ShotlistCommentsCell) where we don't want to show
the various interactive features  */
  isInteractive?: boolean;
  hideBorder?: boolean;
  annotation: CommentAnnotationData | null;
}
interface ActionButton {
  id: string;
  Icon?: any;
  action: CommentActionsType;
  tooltip?: string;
  Component?: React.ComponentType;
}
interface MoreOptionsProps {
  handleEdit?: () => void;
  handleDelete?: () => void;
}
interface ActionButtonsProps extends MoreOptionsProps {
  editable?: boolean;
  canManage?: boolean;
  acknowledged?: boolean;
  handleAction: (action: CommentActionsType) => void;
}

const MoreOptionsComponent: React.FC<MoreOptionsProps> = (props) => {
  const moreOptions = [
    {
      id: 'edit',
      label: 'Edit',
      onItemSelect: props.handleEdit,
    },
    {
      id: 'delete',
      label: 'Delete',
      onItemSelect: props.handleDelete,
    },
  ];
  return (
    <MoreOptions
      options={moreOptions}
      className={`pr-0 pl-0.5 py-0.5`}
      iconClassName="h-[1.4rem] w-auto mt-1.5"
      blurOnClose
    />
  );
};

const ActionButtons: React.FC<ActionButtonsProps> = (props) => {
  const { t } = useTranslation();
  const { canManage, handleAction, acknowledged, editable } = props;

  const buttons: ActionButton[] = [
    {
      id: 'reply',
      Icon: ReplyIcon,
      action: CommentActionsType.ACTION_REPLY,
      tooltip: t(`comments.tooltips.reply`),
    },
  ];
  if (canManage) {
    buttons.push({
      id: 'mark-complete',
      Icon: acknowledged ? CheckCircleSolid : CheckCircle,
      tooltip: t(
        `comments.tooltips.${acknowledged ? 'acknowledged' : 'acknowledge'}`,
      ),
      action: CommentActionsType.ACTION_MARK_COMPLETE,
    });
  }

  if (editable) {
    buttons.unshift({
      id: 'more-options',
      Component: MoreOptionsComponent,
      action: CommentActionsType.ACTION_MORE_OPTIONS,
    });
  }

  return (
    <>
      {buttons.map((button) => (
        <ConditionalTooltip key={button.id} title={button.tooltip}>
          {/* The Tooltip component needs this wrapping div */}
          <div>
            {button.Icon && (
              <div
                className={classNames('py-1 mr-1.5', {
                  'group-hover/card:opacity-100 opacity-0 transition-opacity':
                    !acknowledged ||
                    button.action !== CommentActionsType.ACTION_MARK_COMPLETE,
                })}
              >
                <Icon
                  className={classNames('h-5 w-auto fill-current', {
                    'text-type-green':
                      button.action ===
                        CommentActionsType.ACTION_MARK_COMPLETE &&
                      Boolean(acknowledged),
                  })}
                  onClick={() => handleAction(button.action)}
                  icon={<button.Icon />}
                  fill={
                    button.action === CommentActionsType.ACTION_MARK_COMPLETE &&
                    Boolean(acknowledged)
                  }
                />
              </div>
            )}
            {button.Component && (
              <div className="opacity-0 group-hover/card:opacity-100">
                <button.Component {...props} />
              </div>
            )}
          </div>
        </ConditionalTooltip>
      ))}
    </>
  );
};
const CommentCard: FC<CommentCardProps> = (props) => {
  const {
    id,
    className,
    text,
    userName,
    userId,
    image,
    initial,
    date,
    replies,
    disableActionsOverlap,
    isReply,
    frameId,
    acknowledged,
    canManage,
    hideCompletedComments,
    isInteractive = true,
  } = props;
  const inputRef = useRef<HTMLDivElement | null>(null);
  const COLLAPSED_KEY = `COLLAPSE_THREAD_REPLIES_${id}`;
  const [collapsed, setCollapsed] = usePersistedBoolean(COLLAPSED_KEY, false);
  const shouldHideComment = hideCompletedComments && acknowledged;
  const { t } = useTranslation();
  const { showDialog: showDeleteConfirmationDialog } = useConfirmDialog({
    size: 'confirm',
    isDark: true,
    alignButtons: 'left',
    cancelBtnSize: 'sm',
    confirmBtnSize: 'sm',
    title: t('modals.delete_comment.title'),
    subtitle: t('modals.delete_comment.description'),
    hideDescription: true,
    onConfirm: () => {
      props.onDelete && props.onDelete({ id });
    },
    initialFocus: 'confirmButton',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isReplyBoxVisible, toggleReplyBox] = useToggle(false);
  const currentAnnotation = useStore<
    AnnotationToolEngineProps | null,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => s.currentAnnotation);
  const isAnnotationContextAvailable = useStore<
    boolean,
    CommentAnnotationsStore
  >('commentAnnotations', (s) => s.isPreviewAvailable || s.isEditorAvailable);
  const isCurrentAnnotation = props.id === currentAnnotation?.id?.commentId;
  const currentUserId = useStore<number | undefined, UserStore>(
    'User',
    // The user store might be unpopulated (for example on shareable)
    ({ user }) => user?.id,
  );
  const markAsComplete = () => {
    props.onMarkAsComplete &&
      props.onMarkAsComplete({ id, acknowledged: !acknowledged });
  };
  const handleDelete = () => {
    showDeleteConfirmationDialog();
  };
  const handleEdit = () => {
    setIsEditing(true);
  };

  const editableComments = editableCommentsLocalState.getValue() ?? [];
  const commentIsEditable =
    props.canManage ||
    contains(editableComments, props.id) ||
    props.userId === currentUserId;

  const handleAction = (type: CommentActionsType) => {
    switch (type) {
      case CommentActionsType.ACTION_REPLY:
        toggleReplyBox();
        setTimeout(() => {
          inputRef.current?.focus();
        }, 100);
        return;
      case CommentActionsType.ACTION_MARK_COMPLETE:
        markAsComplete();
        return;
      default:
        return logger.warn(`Operation ${type} not supported`);
    }
  };
  const handleReplySubmit: CommentEditorSubmithandler = ({
    text,
    name,
    annotation,
  }) => {
    toggleReplyBox();
    if (name) commentNameLocalState.setValue(name);
    props.onAddReply &&
      props.onAddReply({
        text,
        name,
        in_reply_to_comment_id: id,
        userId: userId,
        annotation: annotation,
      });
  };
  const handleEditClose = () => {
    setIsEditing(false);
  };
  const handleEditSubmit: CommentEditorSubmithandler = ({
    text,
    annotation,
  }) => {
    props.onEditComment && props.onEditComment({ id, text, annotation });
  };
  const visibleReplies = useMemo(
    () =>
      hideCompletedComments
        ? filter(props.replies ?? [], (reply) => !reply.acknowledged_by)
        : props.replies,
    [hideCompletedComments, props.replies],
  );
  const handleAnnotationButtonClick = React.useCallback(() => {
    CommentAnnotationsActions.toggle({
      commentId: { frameId, commentId: props.id },
      interactive: false,
    });
  }, [frameId, props.id]);

  const repliesSection = (
    <>
      {replies && (
        <CommentList
          isReplySection
          comments={replies}
          frameId={frameId}
          onAddReply={props.onAddReply}
          addIndent={!isReply && !hideCompletedComments}
          onMarkAsComplete={props.onMarkAsComplete}
          onEditComment={props.onEditComment}
          onDelete={props.onDelete}
          canManage={props.canManage}
          isInteractive={isInteractive}
        />
      )}
    </>
  );

  return (
    <>
      {!shouldHideComment && (
        <>
          <div
            className={classNames(
              'flex group/card',
              props.isReply || props.hideBorder
                ? 'border-none'
                : 'border-t border-border',
              !props.isReply && ' py-4',
              className,
            )}
          >
            <div className="flex-shrink-0 mt-2 mr-3">
              <Avatar
                size="sm"
                initial={initial ?? getFirstCharacter(userName)}
                image={image}
              />
            </div>

            <div className="relative flex-grow min-w-0">
              <div className="relative">
                <span className="inline-block mr-1 text-sm font-bold">
                  {userName}
                </span>
                <span className="text-xs text-type-disabled">
                  <RelativeTime
                    date={date}
                    capitalizeFirst
                    skipPrefix
                    includeSeconds
                  />
                </span>

                {/* Gradient Mask */}
                {!props.isReply && (
                  <div className="absolute top-0 bottom-0 right-0 w-1/2 bg-gradient-to-l from-white to-white/0"></div>
                )}
              </div>

              {!isEditing && (
                <div className="">
                  <Markdown className="text-sm prose" text={text} />
                  {notNullOrUndefined(props.annotation) &&
                    isAnnotationContextAvailable &&
                    !isEditing && (
                      <AnnotationToggle
                        inactiveColor="subdued"
                        className="mt-2"
                        label={t(
                          `annotations.buttonLabel.${
                            isCurrentAnnotation ? 'hide' : 'view'
                          }`,
                        )}
                        active={isCurrentAnnotation}
                        onClick={handleAnnotationButtonClick}
                        disabled={isEditing}
                      />
                    )}
                </div>
              )}
              {Boolean(visibleReplies?.length) && collapsed && !isReply && (
                <Button
                  type="text"
                  onClick={setCollapsed.off}
                  className="text-sm font-normal underline no-padding text-type-subdued"
                >
                  {/* TODO: Use correct replies count. There is a comment count in the store but
                  I am not sure if there's a replies count. */}
                  {t('counters.replies', { count: visibleReplies!.length })}
                </Button>
              )}
              {isEditing && (
                <div className="mt-2">
                  {/* When we're editing an existing comment */}
                  <CommentEditor
                    autoFocus
                    value={text}
                    onSubmit={handleEditSubmit}
                    userOverride={userName}
                    onClose={handleEditClose}
                    allowNameField={false}
                    annotation={props.annotation}
                    allowAnnotation={!props.isReply}
                    commentId={props.id}
                  />
                </div>
              )}

              {/* Comment Actions */}
              {isInteractive && (
                <div
                  className={classNames(
                    'actions flex transition duration-150',
                    {
                      'right-0 -top-[0.3rem] absolute': !disableActionsOverlap,
                    },
                  )}
                >
                  <ActionButtons
                    acknowledged={acknowledged}
                    editable={commentIsEditable}
                    canManage={canManage}
                    handleAction={handleAction}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </div>
          {isReplyBoxVisible && (
            <div className="mb-2">
              {/* When we're responding to an existing comment (without there being a list of replies already) */}
              <CommentEditor
                inputRef={inputRef}
                placeholder={t('comments.reply')}
                onSubmit={handleReplySubmit}
                allowNameField={true}
                // We only want to close the reply field if this close event was
                // caused by a manual close or a form submit.
                onClose={(closeWasOnBlur) =>
                  !closeWasOnBlur && toggleReplyBox()
                }
                // We want to start with no annotation
                annotation={null}
                commentId={null}
                allowAnnotation={false}
              />
            </div>
          )}
        </>
      )}
      {!collapsed && Boolean(visibleReplies?.length) && (
        <>
          {isReply ? (
            repliesSection
          ) : (
            <div className="flex flex-col items-center px-4 pt-4 pb-6 rounded-lg bg-surface-light gap-5">
              {repliesSection}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CommentCard;
