import {WizardContext} from 'blackbird/components/wizard/WizardContext';

/** @prettier */
const createReactClass = require('create-react-class');
const { default: NewStoryboardForm } = require('./NewStoryboardForm');

window.NewStoryboardFormContainer = createReactClass({
  getInitialState: function () {
    return NewStoryboardStore.getState();
  },

  componentDidMount: function () {
    NewStoryboardStore.listen(this._onChange);
    NewStoryboardActions.fetchProjectsAndSetId(this.props.projectId);
  },

  componentWillUnmount: function () {
    NewStoryboardStore.unlisten(this._onChange);
  },

  _onChange: function (state) {
    this.setState(state);
  },

  handleCloseClick: function () {
    /** Close flyover on modal close */
    FlyoverActions.close();
  },

  render: function () {
    if (!this.state.projects) {
      return <FlyoverLoading />;
    } else {
      return (
        <WizardContext.Consumer>
          {WizardContext => (
            <NewStoryboardForm wizardContext={WizardContext} onClose={this.handleCloseClick} {...this.state} />
          )}
        </WizardContext.Consumer>
      );
    }
  },
});
