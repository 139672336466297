/** @format */

import React from 'react';
import { Trash2, Move, EllipsisIcon, type LucideIcon } from 'lucide-react';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import Panel from '../../panel/Panel';
import { ProjectsContext } from '../ProjectsContext';

interface Action {
  label: string;
  icon: LucideIcon;
  onClick: () => void;
}

export const ProjectActions: React.FC = () => {
  const { archiveItems, activeProject, singleProjectMoveModal, activeGroup } =
    React.useContext(ProjectsContext);

  const handleDelete = React.useCallback(() => {
    if (activeProject) {
      if (confirm('Are you sure?')) {
        archiveItems({ projectIds: [activeProject.id] });
      }
    }
  }, [activeProject, archiveItems]);

  const handleMove = React.useCallback(() => {
    if (activeProject) {
      singleProjectMoveModal(activeProject);
    }
  }, [activeProject, singleProjectMoveModal]);

  const canMoveProject = activeGroup?.actions.includes('projects.move');
  const canDeleteProject = activeGroup?.actions.includes('projects.delete');

  const options: Action[] = React.useMemo(() => {
    const availableOptions: Action[] = [];
    if (canMoveProject) {
      availableOptions.push({
        label: 'Move to',
        icon: Move,
        onClick: handleMove,
      });
    }
    if (canDeleteProject) {
      availableOptions.push({
        label: 'Delete',
        icon: Trash2,
        onClick: handleDelete,
      });
    }
    return availableOptions;
  }, [canMoveProject, canDeleteProject, handleMove, handleDelete]);

  const handleChange = (action: Action) => {
    action.onClick();
  };

  if (options.length === 0) {
    return null;
  }

  return (
    <Listbox
      as="div"
      className="relative group/projectactions"
      value={null}
      onChange={handleChange}
    >
      {() => (
        <>
          <Listbox.Button className="flex items-center justify-center flex-shrink-0 w-8 h-8 outline-none cursor-pointer rounded-md ring-none hover:bg-surface">
            <EllipsisIcon className="w-4 h-4" />
          </Listbox.Button>
          <Listbox.Options
            as={Panel}
            className="absolute left-0 flex-col w-40 mt-2 border shadow-md rounded-md border-border top-full focus:outline-none"
          >
            <div className="p-2 space-y-1">
              {options.map((option) => (
                <Listbox.Option
                  key={option.label}
                  value={option}
                  as="div"
                  className={({ active }) =>
                    classNames(
                      'cursor-pointer select-none relative pb-2 pt-1.5 px-2 rounded-md flex items-center space-x-2',
                      active ? 'bg-surface-light' : '',
                    )
                  }
                >
                  {({ active }) => (
                    <>
                      <option.icon className="h-4 w-4 pt-0.5" />
                      <span className="text-sm">{option.label}</span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};
