/** @prettier */
import * as React from 'react';
import Logo from 'blackbird/components/logo/Logo';
import classNames from 'classnames';
import { CircularProgressIndicator } from 'javascripts/components/shared/CircularProgressIndicator';
import i18n from 'i18next';
import { HeartIcon } from '@heroicons/react/20/solid';

interface SignupTestProps {
  title: React.ReactNode;
  description?: React.ReactNode;
}

export const SignupTest: React.FC<SignupTestProps> = ({
  title,
  description,
}): React.ReactElement => {
  return (
    <>
      <div className="mx-10 mb-2 text-center space-y-3">
        <legend className="text-2xl font-semibold text-type-primary">
          {title}
        </legend>
        {description && <div className="text-type-subdued">{description}</div>}
      </div>
    </>
  );
};

interface SignupLogoTestProps {
  description?: string;
}

export const SignupLogoTest: React.FC<SignupLogoTestProps> = ({
  description,
}): React.ReactElement => {
  return (
    <div>
      <div
        className={classNames(
          'flex justify-center',
          description ? 'mb-4' : 'mb-8',
        )}
      >
        <div className="flex flex-col items-center">
          <Logo mark={false} className="w-[12rem] mb-6" />

          {description && <div className="text-xl">{description}</div>}

          <div className="flex items-center hidden mt-6 text-sm">
            <span className="flex items-center px-2 pt-1 pb-1.5 bg-surface rounded-md space-x-1 text-type-subdued">
              <HeartIcon className="w-4 mt-[2px] text-brand-pink" />
              <span className="whitespace-nowrap">{`${parseInt(
                i18n.t('signups', { ns: 'stats' }),
              ).toLocaleString(`en-US`)} video pros`}</span>
              <span className="text-type-primary/30 whitespace-nowrap">{`already joined this week`}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
