/** @format */

import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { QuizContext } from './QuizContext';

export const QuizProgress: React.FC = () => {
  const { currentStep } = useContext(QuizContext);
  const [stepNumber, setStepNumber] = useState(1);

  interface ProgressBarProps {
    active?: boolean;
  }

  const ProgressBar: React.FC<ProgressBarProps> = ({ active }) => (
    <div
      className={classNames(
        'h-1.5 rounded-full w-full',
        active ? 'bg-black' : 'bg-surface',
      )}
    />
  );

  useEffect(() => {
    if (currentStep) {
      if (currentStep.id === 'step-pricing') {
        setStepNumber(4);
      } else if (currentStep.id === 'step-newsletter') {
        setStepNumber(4);
      } else if (currentStep.id === 'step-team') {
        setStepNumber(3);
      } else if (currentStep.id === 'step-goals') {
        setStepNumber(2);
      } else if (currentStep.id === 'step-role') {
        setStepNumber(1);
      }
    }
  }, [currentStep]);

  return currentStep &&
    ['step-loading', 'step-proof', 'step-offer', 'step-generate'].includes(
      currentStep.id,
    ) ? null : (
    <div className="flex justify-center w-full ">
      <div className="w-full max-w-sm space-y-4">
        <div className="w-full grid grid-cols-4 gap-1">
          <ProgressBar active />
          <ProgressBar active={stepNumber > 1} />
          <ProgressBar active={stepNumber > 2} />
          <ProgressBar active={stepNumber > 3} />
        </div>
        <div className="text-xs text-center text-type-disabled">
          <>{`Step ${stepNumber} of 4`}</>
        </div>
      </div>
    </div>
  );
};
