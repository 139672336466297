/** @format */

import React from 'react';
import { TeamProvider } from './TeamContext';
import { TeamGlobalManage } from './TeamGlobalManage';

interface TeamGlobalContainerProps {
  teamId: string;
}
export const TeamGlobalContainer: React.FC<TeamGlobalContainerProps> = ({
  teamId,
}) => {
  return (
    <div className="max-w-lg">
      <TeamProvider teamId={teamId}>
        <TeamGlobalManage />
      </TeamProvider>
    </div>
  );
};

(window as any).TeamGlobalContainer = TeamGlobalContainer;
