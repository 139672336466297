/** @prettier */
import React, { useRef, useState, useEffect } from 'react';
import Button from 'blackbird/components/button/Button';
import TextInput from 'blackbird/components/form/text-input/TextInput';
import Checkbox from 'blackbird/components/form/checkbox/Checkbox';
import { type OnboardingProps, OnboardingType } from './Onboarding';
import { Trans, useTranslation } from 'react-i18next';
import { type SubmitHandler, useForm } from 'react-hook-form';
import logger from 'javascripts/helpers/logger';
import { RequestActions } from 'javascripts/flux/actions/request';
import { SignupLogoTest } from './signup/SignupTest';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import LogoGoogle from 'blackbird/images/logo/google-vector.svg';

interface SignUpInput {
  user: {
    name: string;
    email: string;
    password: string;
    terms_of_service: string;
    is_marketing_opt_in?: string;
    token?: string;
  };
  authenticity_token: string;
  inviteToken?: string;
}
const SignupScreen: React.FC<OnboardingProps> = (props) => {
  const {
    register,
    formState,
    handleSubmit: handleValidation,
    reset,
  } = useForm<SignUpInput>({
    defaultValues: {
      user: {
        email: props.invitedEmail ?? '',
      },
    },
  });
  const errors = formState.errors?.user;
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isBusy, setIsBusy] = useState(false);
  const [loginUrl, setLoginUrl] = useState(`/login`);
  const { t } = useTranslation();
  const [notification, setNotification] = useState(props.notification);
  const toggleToEmailScreen = () => {
    props.onToggleScreen && props.onToggleScreen(OnboardingType.SIGN_UP_EMAIL);
  };

  const handleSubmit: SubmitHandler<SignUpInput> = async (data) => {
    const name = data.user.name;
    const email = data.user.email;
    const password = data.user.password;
    if (props.onSignUp) {
      props.onSignUp({ email, password, name });
    } else if (!props.authenticityToken || !props.formAction) {
      throw new Error(
        'An authenticity token and formAction prop need to be supplied',
      );
    }
    setIsBusy(true);
    try {
      const response = await fetch(props.formAction!, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();

      if (response.ok) {
        location.href = jsonResponse.next_url;
      } else {
        return setNotification({
          kind: 'warning',
          message: jsonResponse.error,
        });
      }
    } catch (err) {
      logger.error('Failed to sign up', err);
    } finally {
      setIsBusy(false);
    }
  };
  useEffect(() => {
    if (formState.touchedFields.user?.email) {
      setNotification(undefined);
    }
  }, [formState]);

  useEffect(() => {
    if (!notification) return;
    if (notification.kind === 'error' || notification.kind === 'warning') {
      RequestActions.error.defer(notification.message);
    } else {
      RequestActions.success.defer(notification.message);
    }
  }, [notification]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const nextUrl = urlSearchParams.get('next_url');
    if (nextUrl) {
      setLoginUrl(`/login?next_url=${nextUrl}`);
    }
  }, []);

  return (
    <form
      ref={formRef}
      onSubmit={handleValidation(handleSubmit)}
      className="flex items-center justify-center flex-grow"
      method="post"
      action={props.formAction}
    >
      <input name="utf8" type="hidden" value="✓" />
      {props.authenticityToken && (
        <input
          type="hidden"
          {...register('authenticity_token')}
          value={props.authenticityToken}
        />
      )}
      <fieldset className="flex flex-col flex-grow max-w-md space-y-8">
        <SignupLogoTest description={`Create your account`} />
        {props.inviteToken && (
          <input
            type="hidden"
            {...register('user.token')}
            id="user_token"
            value={props.inviteToken}
          />
        )}
        {props.type === OnboardingType.SIGN_UP_EMAIL ? (
          <div className="flex flex-col gap-6">
            <div className="space-y-2">
              <TextInput
                {...register('user.name', { required: 'Name is required.' })}
                label={t('signup.name')}
                autoComplete="name"
                className="flex-grow-0"
                error={errors?.name?.message}
              />

              <TextInput
                {...register('user.email', { required: 'Email is required.' })}
                label={t('signup.email')}
                type="email"
                className="flex-grow-0"
                error={errors?.email?.message}
              />

              <TextInput
                {...register('user.password', {
                  required: 'Password is required.',
                  minLength: {
                    value: 6,
                    message: 'Password is too short (minimum is 6 characters)',
                  },
                })}
                label={t('signup.password')}
                type="password"
                className="flex-grow-0"
                autoComplete="new-password"
                error={errors?.password?.message}
              />

              <div className="flex items-center justify-between pt-2">
                <Checkbox
                  {...register('user.terms_of_service', {
                    validate: (v) =>
                      Boolean(v) || 'Please accept terms of service.',
                  })}
                  label={
                    <Trans
                      t={t}
                      i18nKey="signup.terms"
                      // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                      components={{
                        terms: (
                          <a
                            className="underline hover:no-underline"
                            href="https://boords.com/page/terms-of-service"
                          >
                            terms and conditions
                          </a>
                        ),
                      }}
                    />
                  }
                  controlled={false}
                  htmlValue="1"
                  error={errors?.terms_of_service?.message}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Button
                loading={formState.isSubmitting || isBusy}
                disabled={formState.isSubmitting || isBusy}
                rounded
                htmlType="submit"
                size="lg"
              >
                {t('signup.button')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col mx-12 gap-4">
            <Button
              size="lg"
              type="outline"
              rounded
              link={props.googleSigninPath}
            >
              <div className="relative w-full">
                <div className="absolute -left-1 top-[0.2rem]">
                  <LogoGoogle
                    width="1.2rem"
                    height="1.2rem"
                    alt="Google Logo"
                  />
                </div>
                <div className="w-full pl-2 text-center">{`Continue with Google`}</div>
              </div>
            </Button>
            <Button
              rounded
              size="lg"
              type="secondary"
              onClick={toggleToEmailScreen}
            >
              <div className="relative w-full">
                <EnvelopeIcon className="w-5 absolute -left-1 top-[0.2rem]" />
                <div>{`Continue with email`}</div>
              </div>
            </Button>
          </div>
        )}

        <div className="flex items-center justify-center space-x-1 text-type-subdued">
          <p>{`Already have an account?`}</p>
          <a
            className="underline hover:no-underline text-type-primary"
            href={loginUrl}
          >{`Sign in here`}</a>
        </div>
      </fieldset>
    </form>
  );
};
export default SignupScreen;
